<template>
	<!-- 公用的内容-->
	<div style="overflow:hidden !important">
		<div style="text-align: left !important; padding-left: 10px; padding-top: 10px;">
			<span style="font-weight: bolder;">系统管理&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
			<span>/&nbsp;用户管理</span>
		</div>
		<div>
			<el-divider></el-divider>
		</div>
		<div style="overflow:hidden">
			<el-form ref="form" :model="form" label-width="80px" style="padding-top: 20px;">
				<div style="float: left; padding-left: 200px;">
					<el-form-item label="用户ID" class="kkk">
						<el-input v-model="form.id" placeholder="请输入内容"></el-input>
					</el-form-item>
					<el-form-item label="姓名" class="kkk">
						<el-input v-model="form.name" placeholder="请输入内容"></el-input>
					</el-form-item>
					<el-form-item label="类别" class="kkk">
						<el-select  filterable v-model="form.systype" placeholder="请选择类别">
							<el-option label="信息科" value="信息科"></el-option>
							<el-option label="前台" value="前台"></el-option>
							<el-option label="药房" value="药房"></el-option>
							<el-option label="门诊" value="门诊"></el-option>
							<el-option label="医技" value="医技"></el-option>
							<el-option label=""></el-option>
						</el-select>
					</el-form-item>
				</div>
				<el-button icon="el-icon-search" type="primary" v-on:click="onSubmit('form')"
					style="width:120px; height: 50px; margin-left:0px ;" round>查询</el-button>
				<el-button icon="el-icon-plus" type="success" @click="dialogVisible = true"
					style="width:120px; height: 50px;" round>新增用户</el-button>
				<el-dialog title="提示" :visible.sync="dialogVisible" width="40%" :append-to-body="true"
					:before-close="handleClose">

					<div style=" margin-left:100px ;">
						<el-form ref="form1" :model="childTable" label-width="80px" style="padding-top: 20px;">
							<div style="">
								<el-form-item label="用户ID" class="kkk">
									<el-input v-model="childTable.id" placeholder="请输入内容"></el-input>
								</el-form-item>
								<el-form-item label="密码" class="kkk">
									<el-input v-model="childTable.password" placeholder="请输入内容"></el-input>
								</el-form-item>
							</div>
							<div style="">
								<el-form-item label="姓名" class="kkk">
									<el-input v-model="childTable.name" placeholder="请输入内容"></el-input>
								</el-form-item>
							</div>
							<div style="">
								<el-form-item label="类别" class="kkk1">
									<el-input v-model="childTable.systype" placeholder="请输入内容"></el-input>
								</el-form-item>
								<el-form-item label="职称" class="kkk1">
									<el-input v-model="childTable.hisrank" placeholder="请输入内容"></el-input>
								</el-form-item>
							</div>
							<div style="">
								<el-form-item label="科室" class="kkk1">
									<el-input v-model="childTable.depart" placeholder="请输入内容"></el-input>
								</el-form-item>
								<el-form-item label="挂号级别" class="kkk1">
									<el-input v-model="childTable.regtype" placeholder="请输入内容"></el-input>
								</el-form-item>
							</div>
						</el-form>
					</div>

					<span slot="footer" class="dialog-footer">
						<el-button @click="dialogVisible = false">取 消</el-button>
						<el-button type="primary" v-on:click="addUser('form1')">确 定</el-button>
					</span>
				</el-dialog>
				<div style="float: left; padding-left: 198px;">
					<el-form-item   label="职称" class="kkk1">
						<el-select filterable v-model="form.hisrank" placeholder="请选择职称">
							<el-option label="医师" value="医师"></el-option>
							<el-option label="药师" value="药师"></el-option>
							<el-option label="副主任医师" value="副主任医师"></el-option>
							<el-option label="主任医师" value="主任医师"></el-option>
							<el-option label=""></el-option>
						</el-select>
					</el-form-item>
					<el-form-item   label="科室" class="kkk1">
						<el-select filterable v-model="form.depart" placeholder="请选择科室">
							<el-option label="内科" value="内科"></el-option>
							<el-option label="普通外科" value="普通外科"></el-option>
							<el-option label="耳鼻喉科" value="耳鼻喉科"></el-option>
							<el-option label="骨外科" value="骨外科"></el-option>
							<el-option label="肛肠科" value="肛肠科"></el-option>
							<el-option label="泌尿科" value="泌尿科"></el-option>
							<el-option label="肿瘤科" value="肿瘤科"></el-option>
							<el-option label="前台" value="前台"></el-option>
							<el-option label="药房" value="药房"></el-option>
							<el-option label="管理" value="管理"></el-option>
							<el-option label="脑科1号" value="脑壳1号"></el-option>
							<el-option label=""></el-option>
						</el-select>
					</el-form-item>
				</div>
			</el-form>
		</div>
		<div style="margin: 10px; margin-left: 200px;">
			<el-table ref="multipleTable" :data="table" height="300" tooltip-effect="dark" border="true"
				:header-cell-style="{'text-align':'center'}" style="width: 85%">
				<el-table-column prop="id" label="用户ID" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.show">
							<el-input size="mini" v-model="scope.row.id"></el-input>
						</span>
						<span v-else>{{ scope.row.id }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="姓名" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.show">
							<el-input size="mini" v-model="scope.row.name"></el-input>
						</span>
						<span v-else>{{ scope.row.name }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="systype" label="类别" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.show">
							<el-select   v-model="scope.row.systype" placeholder="请选择类别">
								<el-option label="信息科" value="信息科"></el-option>
								<el-option label="前台" value="前台"></el-option>
								<el-option label="药房" value="药房"></el-option>
								<el-option label="门诊" value="门诊"></el-option>
								<el-option label="医技" value="医技"></el-option>
								<el-option label=""></el-option>
							</el-select>
						</span>
						<span v-else>{{ scope.row.systype }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hisrank" label="职称" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.show">
							<el-select  v-model="scope.row.hisrank" placeholder="请选择职称">
								<el-option label="医师" value="医师"></el-option>
								<el-option label="药师" value="药师"></el-option>
								<el-option label="副主任医师" value="副主任医师"></el-option>
								<el-option label="主任医师" value="主任医师"></el-option>
								<el-option label=""></el-option>
							</el-select>
						</span>
						<span v-else>{{ scope.row.hisrank }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="depart" label="科室" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.show">
							<el-select   v-model="scope.row.depart" placeholder="请选择科室">
								<el-option label="内科" value="内科"></el-option>
								<el-option label="普通外科" value="普通外科"></el-option>
								<el-option label="耳鼻喉科" value="耳鼻喉科"></el-option>
								<el-option label="骨外科" value="骨外科"></el-option>
								<el-option label="肛肠科" value="肛肠科"></el-option>
								<el-option label="泌尿科" value="泌尿科"></el-option>
								<el-option label="肿瘤科" value="肿瘤科"></el-option>
								<el-option label="前台" value="前台"></el-option>
								<el-option label="药房" value="药房"></el-option>
								<el-option label="管理" value="管理"></el-option>
								<el-option label="脑科1号" value="脑壳1号"></el-option>
								<el-option label=""></el-option>
							</el-select>
						</span>
						<span v-else>{{ scope.row.depart }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="regtype" label="挂号级别" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.show">
							<el-input size="mini" v-model="scope.row.regtype"></el-input>
						</span>
						<span v-else>{{ scope.row.regtype }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="password" label="密码" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.show">
							<el-input size="mini" v-model="scope.row.password"></el-input>
						</span>
						<span v-else>{{ scope.row.password }}</span>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" align="center">
					<template slot-scope="scope">
						<span v-if="(ssstate===true) || (scope.row.show ===false)">
							<el-button @click="editRow(scope.row)" type="text" size="small">
								编辑
							</el-button>
							<el-button @click="deleteRow(scope.$index, table,scope.row)" type="text" size="small">
								<span style="color: red;">移除</span>
							</el-button>
						</span>
						<span v-else>
							<el-button @click="canRow(scope.row)" type="text" size="small">
								<span style="color: green;">保存</span>
							</el-button>
							<el-button @click="canon(scope.row)" type="text" size="small">
								<span style="color: blueviolet;">取消</span>
							</el-button>
						</span>
					</template>
				</el-table-column>
			</el-table>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				ssstate: true,
				childTable: {
					id: '',
					password: '',
					name: '',
					systype: '',
					hisrank: '',
					depart: '',
					regtype: ''
				},
				table: [{
					id: '',
					password: '',
					name: '',
					systype: '',
					hisrank: '',
					depart: '',
					regtype: '',
					show: false
				}],
				dialogVisible: false,
				form: {
					id: '',
					name: '',
					systype: '',
					hisrank: '',
					depart: ''
				},
			}
		},
		created() { //页面一加载就读取
			this.getAllUsers();
		},
		methods: {
			onSubmit(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (!valid) {
						this.$message.error("验证失败！");
						return;
					}
					// 使用 vue-router 路由到指定页面，该方式称之为编程式导航
					const {
						data: res
					} = await this.$http.post("getUsersBy", this.form);
					if (res.flag == "yes") {
						this.$message.success('查询成功!')
						this.table = res.users;
					} else {
						this.$message.error("没有该用户！");
						this.table = [];
					}
				});
			},
			editRow(rows) {
				rows.show = true;
				this.ssstate = false
			},
			canRow(rows) {
				this.delete("updateUser", rows);
				rows.show = false;
				this.ssstate = true
			},
			deleteRow(index, rows, row) {
				this.delete("deleteById", row);
				rows.splice(index, 1);

			},
			canon(rows){				
				rows.show = false;
				this.ssstate = true
				this.getAllUsers()
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						done()
					})
					.catch(_ => {})
			},
			async getAllUsers() { //
				const {
					data: res
				} = await this.$http.get("getAllUsers");
				console.log(res);
				this.table = res.Users;
			},
			async delete(str, row) { //
				const {
					data: res
				} = await this.$http.post(str, row);
				console.log(res);
			},
			addUser(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (!valid) {
						this.$message.error("验证失败！");
						return;
					}
					// 使用 vue-router 路由到指定页面，该方式称之为编程式导航
					console.log(this.childTable);
					console.log(this.childTable.systype);
					const {
						data: res
					} = await this.$http.post("adduser", this.childTable);
					if (res.isAdd == "true") {
						this.$message.success("添加成功！");
						this.dialogVisible = false;
					} else {
						this.$message.error("已存在该用户！");
					}
				});
			},


		}
	}
</script>

<style>
</style>
