<template>
  <div style="overflow:hidden !important">
    <div style="text-align: left !important; padding-left: 10px;">
      <span style="font-weight: bolder;">前台&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span>/&nbsp;退号</span>
    </div>
    <div>
      <el-divider></el-divider>
    </div>
    <div style="text-align: left !important; padding-left: 10px; font-size: 24px;">
      信息查询：
    </div>
    <div style="overflow:hidden;">
      <el-form ref="form" :model="form" label-width="80px" style="padding-top: 20px;">
        <div style="float: left; padding-left: 200px;">
          <el-form-item label="病历号" class="kkk">
            <el-input v-model="form.medRecNum" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="姓名" class="kkk">
            <el-input v-model="form.name" placeholder="请输入内容"></el-input>
          </el-form-item>
          &nbsp;&nbsp;&nbsp;
          <el-form-item label="身份证号" class="kkk">
            <el-input v-model="form.idNum" placeholder="请输入内容"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div style="float:right !important; padding-right: 280px; padding-top: 0px;">
        <el-button icon="el-icon-search" type="primary" v-on:click="onSubmit()" style="width:100px; height: 40px;" round>查询</el-button>
      </div>
    </div>
    <div>
      <el-divider></el-divider>
    </div>
    <div style="text-align: left; font-size: 24px; margin: 10px;">患者挂号信息：</div>
    <div>
      <el-table
          :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)"
          height="380"
          :header-cell-style="{'text-align':'center'}"
          style="width: 98%; margin-left: 10px;"
          border="true">
          <el-table-column
            prop="medRecNum"
            label="病历号"
            width="180"
            align="center">
          </el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="180"
            align="center">
          </el-table-column>
          <el-table-column
            prop="idNum"
            label="身份证号"
            align="center">
          </el-table-column>
		  <el-table-column
		    prop="regID"
		    label="挂号单号"
		    align="center">
		  </el-table-column>
          <el-table-column
            prop="regDate"
            label="挂号日期"
            align="center">
          </el-table-column>
          <el-table-column
            prop="optDepart"
            label="看诊科室"
            align="center">
          </el-table-column>
          <el-table-column
            prop="state"
            label="状态"
            align="center">
          </el-table-column>
          <el-table-column
            prop="address"
            label="操作"
            align="center">
			<template slot-scope="scope">
				<el-button icon="el-icon-close" type="danger" style="width:100px; height: 40px;" round  @click="canRow(scope.row)">退号</el-button>
			</template>
          </el-table-column>
        </el-table>
		<el-pagination
		  @size-change="handleSizeChange"
		  @current-change="handleCurrentChange"
		  :current-page="currentPage"
		  :page-sizes="[5,10,15,20]"
		  :page-size="pageSize"
		  layout="total, sizes, prev, pager, next"
		  :total="tableData.length">
		</el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
		pageSize: 10,
		currentPage: 1,
      tableData: [{
		regID:'',
        medRecNum: 'abfassd',
        name: '王小虎',
        idNum: '上海市普陀区金沙江路 1518 弄',
        regDate: '2021.6.5',
        optDepart: '内科',
        state: '未诊'
      }
      ],
      form: {
        medRecNum: '',
        name: '',
        idNum: ''
      }
    }
  },
  created () {
    this.loading()//加载所有的患者挂号信息
  },
  methods: {
    async onSubmit () {//查询操作
      const {
        data: res
      } = await this.$http.post('reception/withby',{"medRecNum":this.form.medRecNum,"name":this.form.name,"idNum":this.form.idNum})
	  this.tableData = res.list;
    },
	async loading () {//初始化
	  const {
	    data: res
	  } = await this.$http.get('reception/loadingwith')
	  this.tableData = res.list;
	},
	handleSizeChange(val) {
	    this.pageSize = val;
	},
	handleCurrentChange(val) {
	    this.currentPage = val;
	},
	async canRow (row) {//退号操作
	if(row.state==='已挂号'){
		const {
		  data: res
		} = await this.$http.post('reception/czwith',row)
		if(res.flag=="true"){
			this.$message.success("退号成功")
			this.loading()
		}else{
			this.$message.error("退号失败！")
		}
	}else{
		this.$message.warning("不可操作！")
	}    
    },
  }
}
</script>

<style>
  .el-icon-my-export{
      background: url(../../assets/image/支付宝.png) center no-repeat;
      background-size: cover;
  }
  .el-icon-my-export:before{
      content: "替";
      font-size: 85px;
      visibility: hidden;
  }
  .el-icon-my-export1{
      background: url(../../assets/image/微信.png) center no-repeat;
      background-size: cover;
  }
  .el-icon-my-export1:before{
      content: "替";
      font-size: 85px;
      visibility: hidden;
  }
  .kkl{
    display: inline-block !important;
  }
  .kkk {
    display: inline-block !important;
  }
  .kkk1 {
    display: inline-block !important;
    ;
  }
</style>
