<template>
	<div style="overflow:hidden !important">
		<div style="text-align: left !important; padding-left: 10px;">
			<span style="font-weight: bolder;">前台&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
			<span>/&nbsp;患者费用查询</span>
		</div>
		<div>
			<el-divider></el-divider>
		</div>
		<div style="text-align: left !important; padding-left: 10px; font-size: 24px;">
			信息查询：
		</div>
		<div style="overflow:hidden">
			<el-form ref="form" :model="form" label-width="80px" style="padding-top: 20px;">
				<div style="float: left; padding-left: 200px;">
					<el-form-item label="病历号" class="kkk">
						<el-input v-model="form.medRecNum" placeholder="请输入内容"></el-input>
					</el-form-item>
					<el-form-item label="姓名" class="kkk">
						<el-input v-model="form.name" placeholder="请输入内容"></el-input>
					</el-form-item>
					&nbsp;&nbsp;&nbsp;
					<el-form-item label="身份证号" class="kkk">
						<el-input v-model="form.idNum" placeholder="请输入内容"></el-input>
					</el-form-item>
				</div>
			</el-form>
			<div style="float:right !important; padding-right: 280px; padding-top: 0px;">
				<el-button v-on:click="onSubmit()" style="width:100px; height: 40px;" round>查询</el-button>
			</div>
		</div>
		<div>
			<el-divider></el-divider>
		</div>
		<div style="text-align: left; font-size: 24px; margin: 10px;">患者费用信息：</div>
		<div>
			<el-table :data="tableData.slice((this.currentPage-1)*this.pagesize,this.currentPage*this.pagesize)"
				height="312" style="width: 98%; margin-left: 10px; margin-top: 20px;" border="true">
				<el-table-column prop="medRecNum" label="病历号" width="170" align="center">
				</el-table-column>
				<el-table-column prop="name" label="姓名" width="170" align="center">
				</el-table-column>
				<el-table-column prop="idNum" label="身份证号" align="center">
				</el-table-column>
				<el-table-column prop="payCon" label="缴费项目名称" align="center">
				</el-table-column>
				<el-table-column prop="quantity" label="数量" align="center">
				</el-table-column>
				<el-table-column prop="unitPrice" label="单价" align="center">
				</el-table-column>
				<el-table-column prop="totalPrice" label="总价" align="center">
				</el-table-column>
				<el-table-column prop="itemState" label="项目状态" align="center">
				</el-table-column>
				<el-table-column prop="payTime" label="时间" align="center">
				</el-table-column>
			</el-table>
			<div style="margin-top: 10px;">
				<el-pagination
				  @size-change="handleSizeChange"
				  @current-change="handleCurrentChange"
				  :current-page="currentPage"
				  :page-size="pagesize"
				  :page-sizes="[5,10,15,20]"
				  layout="total, sizes, prev, pager, next"
				  :total="tableData.length">
				</el-pagination>
			</div>
		</div>
		<div style="font-size: 28px; text-align: left; margin: 10px; margin-top: 25px;">
			<!-- 总价：{{tableData.totalPrice}}元 -->
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				pagesize: 10, //每页多少条
				currentPage: 1, // 当前页
				tableData: [{
					medRecNum: '',
					name: '',
					idNum: '',
					payCon: '',
					quantity: '',
					unitPrice: '',
					totalPrice: '',
					itemState: '',
					payTime: ''
				}],
				form: {
					medRecNum: '',
					name: '',
					idNum: ''
				}
			}
		},
		created() {
			this.getAllfrees()
		},
		methods: {
			async getAllfrees() {
				const {
					data: res
				} = await this.$http.get('getAllfrees')
				console.log(res)
				this.tableData = res.frees
			},
			handleSizeChange(val) {
				this.pagesize = val;
			},
			handleCurrentChange(val) {
				this.currentPage = val;
			},
			async onSubmit() {
				const {
					data: res
				} = await this.$http.post('Submitform', this.form)
				if (res.flag === 'yes') {
					this.$message.success('查询成功！')
					this.tableData = res.table
				} else if (res.flag === 'no') {
					this.$message.warning('未输入！')
					this.getAllfrees()
				} else {
					this.$message.error('没有该用户费用记录！')
					this.tableData = []
				}
			}
		}
	}
</script>

<style>
	.el-icon-my-export {
		background: url(../../assets/image/支付宝.png) center no-repeat;
		background-size: cover;
	}

	.el-icon-my-export:before {
		content: "替";
		font-size: 85px;
		visibility: hidden;
	}

	.el-icon-my-export1 {
		background: url(../../assets/image/微信.png) center no-repeat;
		background-size: cover;
	}

	.el-icon-my-export1:before {
		content: "替";
		font-size: 85px;
		visibility: hidden;
	}

	.kkl {
		display: inline-block !important;
	}

	.kkk {
		display: inline-block !important;
	}

	.kkk1 {
		display: inline-block !important;
		;
	}
</style>
