<template>
	<div style="overflow: scroll; margin: 0px;background-color: #E9EEF3; height: 590px; font-size: 20px;"
		class="grid-content bg-purple">
		<el-row>
			<el-col :span="12">
				<div style="margin:10px;">
					<div style="text-align: left;">
						检查项：
					</div>
					<div style=" padding-bottom: 10px; padding-top: 20px;">
						<el-table ref="multipleTable" :data="table" height="485px" max-height="485px"
							tooltip-effect="dark" border="true" @selection-change="handleSelectionChange"
							:header-cell-style="{'text-align':'center'}" style="width: 100%">
							<el-table-column prop="type" label="类别" align="center">
							</el-table-column>
							<el-table-column prop="name" label="名称" align="center">
							</el-table-column>
							<el-table-column prop="time" label="时间" align="center">
							</el-table-column>
							<el-table-column label="结果" align="center">
							 <template slot-scope="scope">
						        <el-button icon="el-icon-view" type="text" @click="()=>{
 									picture =	scope.row.picture
 									result = scope.row.result
						        dialogVisible2 = true}
						        ">查看
								</el-button>
						      </template>
								
							</el-table-column>
						</el-table>
					</div>
				</div>
			</el-col>
			<el-col :span="12">
				<div>
					<div style="text-align: left; margin: 10px; margin-top: 6px;">
						<span>*西医诊断</span>
						<span style="padding-left: 346px;">
							<el-button icon="el-icon-plus" type="success" @click="dialogVisible1 = true">添加</el-button>
						</span>
						<el-dialog title="添加西医诊断" :visible.sync="dialogVisible1" width="30%" :before-close="handleClose"
							:append-to-body="true">
							<div style="overflow:hidden">
								<el-form ref="form1" :model="childtable1" label-width="80px" style="padding-top: 20px;">
									<div style="padding-left: 0px;">
										<el-form-item label="诊断名称" class="kkl">
											<el-select filterable v-model="departoptions1" placeholder="请选择">
												<el-option v-for="item in childtable1" :key="item.value"
													:label="item.label" :value="item.value">
												</el-option>
											</el-select>
										</el-form-item>
									</div>
								</el-form>
							</div>
							<span slot="footer" class="dialog-footer">
								<el-button type="warning" @click="dialogVisible1 = false">取 消</el-button>
								<el-button type="primary" @click="addwicd()">确 定</el-button>
							</span>
						</el-dialog>
					</div>
					<div style="padding-left: 10px; padding-bottom: 5px; margin-right: 20px;">
						<el-table :data="table1" height="210" max-height="210px"
							:header-cell-style="{'text-align':'center'}" border="true" style="width: 100%;">
							<el-table-column prop="wicd" label="ICD编码" align="center">
							</el-table-column>
							<el-table-column prop="wname" label="名称" align="center">
							</el-table-column>
							<el-table-column label="时间" prop="wtime" align="center">
							</el-table-column>
							<el-table-column label="操作" align="center">
								<template slot-scope="scope">
									<el-button icon="el-icon-delete" type="danger" @click="wdelect(scope.row)">删除
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div style="text-align: left; margin: 10px;">
						<span>*中医诊断</span>
						<span style="padding-left: 346px;">
							<el-button icon="el-icon-plus" type="success" @click="dialogVisible = true">添加</el-button>
						</span>
						<el-dialog title="添加中医诊断" :visible.sync="dialogVisible" width="30%" :before-close="handleClose"
							:append-to-body="true">
							<div style="overflow:hidden">
								<el-form ref="form1" :model="childtable2" label-width="80px" style="padding-top: 20px;">
									<div style="padding-left: 0px;">
										<el-form-item label="名称" class="kkl">
											<el-select filterable v-model="departoptions2" placeholder="请选择">
												<el-option v-for="item in childtable2" :key="item.value"
													:label="item.label" :value="item.value">
												</el-option>
											</el-select>
										</el-form-item>
									</div>
								</el-form>
							</div>
							<span slot="footer" class="dialog-footer">
								<el-button type="warning" @click="dialogVisible = false">取 消</el-button>
								<el-button type="primary" @click="addeicd()">确 定</el-button>
							</span>
						</el-dialog>
					</div>
					<div style="padding-left: 10px; padding-bottom: 5px; margin-right: 20px;">
						<el-table :data="table2" height="190" max-height="190px"
							:header-cell-style="{'text-align':'center'}" border="true" style="width: 100%;">
							<el-table-column prop="eicd" label="ICD编码" align="center">
							</el-table-column>
							<el-table-column prop="ename" label="名称" align="center">
							</el-table-column>
							<el-table-column label="时间" prop="etime" align="center">
							</el-table-column>
							<el-table-column label="操作" align="center">
								<template slot-scope="scope">
									<el-button icon="el-icon-delete" type="danger" @click="edelect(scope.row)">删除
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-form ref="form" :model="form" style="margin: 10px;">
			<div>
				<el-form-item class="uuu" label="诊断结果:">
					<el-input type="textarea" :rows="4" style="width:100%;" v-model="form.disrsu" placeholder="请输入内容">
					</el-input>
				</el-form-item>
				<el-form-item class="uuu" label="处理意见">
					<el-input type="textarea" :rows="4" style="width:100%;" v-model="form.dissug" placeholder="请输入内容">
					</el-input>
				</el-form-item>
			</div>
		</el-form>
		<div style="padding-top: 10px;">
			<el-button icon="el-icon-delete" type="danger" v-on:click="clear()" style="width:100px; height: 50px;"
				round>清空</el-button>
			<el-button icon="el-icon-check" type="primary" v-on:click="onSubmit()" style="width:100px; height: 50px;"
				round>保存</el-button>
		</div>


		<el-dialog title="提示" :visible.sync="dialogVisible2" width="30%"
									:before-close="handleClose" :append-to-body="true" height="561px">
									<p><b>化验报告:</b></p>
									{{this.result}}
									<br/>
									<br/>
									<br/>
									<el-image
									      style="width: 521px; height: 561px"
									      :src="picture"></el-image>
									<span slot="footer" class="dialog-footer">
										<el-button @click="dialogVisible2 = false">取 消</el-button>
										<el-button type="primary" @click="dialogVisible2 = false">确 定</el-button>
									</span>
								</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
				dialogVisible2: false,
				dialogVisible1: false,
				dialogVisible: false,
				picture: '',
				userid: '',
				docid: '',
				table: [{
					type: '',
					name: '',
					time: '',
					picture: ''
				}],
				table1: [{ //西医诊断
					wicd: '',
					wname: '',
					wtime: ''
				}],
				childtable1: [{
					label: '',
					value: ''
				}],
				table2: [{ //中医诊断
					eicd: '',
					ename: '',
					etime: ''
				}],
				childtable2: [{
					label: '',
					value: ''
				}],
				departoptions1: '',
				departoptions2: '',
				form: {
					dissug: 'h', //处理意见
					disrsu: 'j' //诊断结果
				}
			}
		},
		created() { //页面一加载就读取
			this.loading();
		},
		computed: {
			sstate() {
				return this.$store.state.sstate
			}
		},
		watch: {
			sstate() {
				this.loading()
			}
		},
		methods: {
			async addwicd() {
				const {
					data: res
				} = await this.$http.post('outdepart/addwicd', {
					"regID": this.$store.state.sstate,
					"icdID": this.departoptions1
				})

				if (res.flag == "true") {
					this.loading()
				} else {
					this.$message.error("添加失败")
				}
				this.dialogVisible1 = false
			},
			async addeicd() {
				const {
					data: res
				} = await this.$http.post('outdepart/dia/addeicd', {
					"regID": this.$store.state.sstate,
					"icdID": this.departoptions2
				})

				if (res.flag == "true") {
					this.loading()
				} else {
					this.$message.error("添加失败")
				}
				this.dialogVisible = false
			},
			async loading() {
				const {
					data: res
				} = await this.$http.post("outdepart/dia/getQueZhenDate", {
					"patient_reg_id": this.$store.state.sstate
				});
				if (res.isAdd == "true") {
					this.$message.success("获取成功！");
					this.dialogVisible = false;
					this.form = res.form;
					this.table1 = res.table1;
					this.table2 = res.table2;
					this.table = res.table;
					this.childtable1 = res.childtable1;
					this.childtable2 = res.childtable2;
				}
			},
			async searchrsu(row) {
				const {
					data: res
				} = await this.$http.post('searchrsu', this.userid, this.docid, this.docid, row)
				this.picture = res.picture
			},
			async onSubmit() { //保存按钮
				const {
					data: res
				} = await this.$http.post('outdepart/dia/update', {
					"regID": this.$store.state.sstate,
					"form": this.form
				})
				if (res.flag == "true") {
					this.$message.success("更新成功")
				} else {
					this.$message.error("更新失败")
				}

			},
			clear() {
				this.form.disrsu = ''
				this.form.dissug = ''
			},
			async wdelect(row) {
				const {
					data: res
				} = await this.$http.post('outdepart/dia/delete', {
					"regID": this.$store.state.sstate,
					"icdID": row.wicd
				})
				if (res.flag == "true") {
					this.loading()
				} else {
					this.$message.error("添加失败")
				}
			},
			async edelect(row) {
				const {
					data: res
				} = await this.$http.post('outdepart/dia/delete', {
					"regID": this.$store.state.sstate,
					"icdID": row.eicd
				})
				if (res.flag == "true") {
					this.loading()
				} else {
					this.$message.error("添加失败")
				}
			}
		}
	}
</script>

<style>
	.uuu .el-form-item__label {
		font-size: 22px !important;
		font-weight: 100px;
		line-height: 40px;
	}
</style>
