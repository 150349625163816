<template>
  <div style="overflow:hidden !important">
    <div style="text-align: left !important; padding-left: 10px;">
      <span style="font-weight: bolder;">前台&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span>/&nbsp;缴费退费</span>
    </div>
    <div>
      <el-divider></el-divider>
    </div>
    <div style="text-align: left !important; padding-left: 10px; font-size: 24px;">
      信息查询：
    </div>
    <div style="overflow:hidden">
      <el-form ref="form" :model="form" label-width="80px" style="padding-top: 20px;">
        <div style="float: left; padding-left: 200px;">
          <el-form-item label="病历号" class="kkk">
            <el-input v-model="form.medRecNum" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="姓名" class="kkk">
            <el-input v-model="form.name" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" class="kkk">
            <el-input v-model="form.idNum" placeholder="请输入内容"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div style="float:right !important; padding-right: 280px; padding-top: 0px;">
        <el-button icon="el-icon-search" type="primary" v-on:click="onSubmit()" style="width:100px; height: 40px;" round>提交</el-button>
      </div>
    </div>
    <div>
      <el-divider></el-divider>
    </div>
    <div style="text-align: left; margin: 10px; font-size: 24px;">患者缴费信息：</div>
    <div style="padding-left: 1280px;">
      <el-button icon="el-icon-close" type="danger" v-on:click="onSubmit()" style="width:100px; height: 40px;" round>退费</el-button>
      <el-button icon="el-icon-check" type="success" v-on:click="onSubmit()" style="width:100px; height: 40px;" round>缴费</el-button>
    </div>
    <div>
      <el-divider></el-divider>
    </div>
    <div>
      <el-table
          ref="multipleTable"
          :data="tableData"
          height="300"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          border="true"
          style="width: 98%; margin-left:10px ;">
          <el-table-column
            type="selection"
            width="40">
          </el-table-column>
          <el-table-column
            prop="medRecNum"
            label="病历号"
            width="180">
          </el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="180">
          </el-table-column>
          <el-table-column
            prop="idNum"
            label="身份证号">
          </el-table-column>
          <el-table-column
            prop="payCon"
            label="缴费项目名称">
          </el-table-column>
          <el-table-column
            prop="quantity"
            label="数量">
          </el-table-column>
          <el-table-column
            prop="unitPrice"
            label="单价">
          </el-table-column>
          <el-table-column
            prop="totalPrice"
            label="总价">
          </el-table-column>
          <el-table-column
            prop="payState"
            label="缴费状态">
          </el-table-column>
          <el-table-column
            prop="itemState"
            label="项目状态">
          </el-table-column>
          <el-table-column
            prop="payTime"
            label="缴费时间">
          </el-table-column>
        </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        medRecNum: 'abfassd',
        name: '王小虎',
        idNum: '上海市普陀区金沙江路 1518 弄',
        payCon: '13元',
        quantity: '数量',
        unitPrice: '单价',
        totalPrice: '总价',
        payState: '缴费状态',
        itemState: '项目状态',
        payTime: '缴费时间'
      },
      {
        medRecNum: 'abfassd',
        name: '王小虎',
        idNum: '上海市普陀区金沙江路 1518 弄',
        payCon: '13元',
        quantity: '数量',
        unitPrice: '单价',
        totalPrice: '总价',
        payState: '缴费状态',
        itemState: '项目状态',
        payTime: '缴费时间'
      },
      {
        medRecNum: 'abfassd',
        name: '王小虎',
        idNum: '上海市普陀区金沙江路 1518 弄',
        payCon: '13元',
        quantity: '数量',
        unitPrice: '单价',
        totalPrice: '总价',
        payState: '缴费状态',
        itemState: '项目状态',
        payTime: '缴费时间'
      },
      {
        medRecNum: 'abfassd',
        name: '王小虎',
        idNum: '上海市普陀区金沙江路 1518 弄',
        payCon: '13元',
        quantity: '数量',
        unitPrice: '单价',
        totalPrice: '总价',
        payState: '缴费状态',
        itemState: '项目状态',
        payTime: '缴费时间'
      }
      ],
      form: {
        medRecNum: '',
        name: '',
        idNum: ''
      }
    }
  },
  methods: {
    onSubmit () {
      console.log(this.form)
    }
  }
}
</script>

<style>
  .el-divider--horizontal{
    margin: 25px !important;
  }
  .el-icon-my-export{
      background: url(../../assets/image/支付宝.png) center no-repeat;
      background-size: cover;
  }
  .el-icon-my-export:before{
      content: "替";
      font-size: 85px;
      visibility: hidden;
  }
  .el-icon-my-export1{
      background: url(../../assets/image/微信.png) center no-repeat;
      background-size: cover;
  }
  .el-icon-my-export1:before{
      content: "替";
      font-size: 85px;
      visibility: hidden;
  }
  .kkl{
    display: inline-block !important;
  }
  .kkk {
    display: inline-block !important;
  }
  .kkk1 {
    display: inline-block !important;
    ;
  }
</style>
