import router from './router'

router.beforeEach((to, from, next) => {
  if (sessionStorage.getItem('accessToken')) {
    next()
  } else {
    if (to.path ==="/login"){
      next()
    }else {
      next('/login')
    }
  }
});