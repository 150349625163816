<template>
	<el-row :gutter="20">
		<el-col :span="6">
			<div style="height: 90px; overflow:hidden; background-color: #f5f7fa; border-radius: 2px;"
				class="grid-content bg-purple">
				<el-form ref="form" :model="form" label-width="80px" style="padding-top: 20px;">
					<div style="float: left; background-color: #f5f7fa;">
						<el-form-item label="姓名">
							<el-input style="width:150px;" v-model="form.patient_Name" placeholder="请输入内容"></el-input>
							&nbsp;&nbsp;&nbsp;
							<el-button v-on:click="Inquiryuser()" style="width:100px; height: 40px;" round>查询
							</el-button>
						</el-form-item>
					</div>
				</el-form>
			</div>
			<div style="margin-bottom: 60px;">
				<el-table :data="table" max-height="460px" style="width: 100%;"
					:header-cell-style="{'text-align':'center'}">
					<el-table-column label="患者">
						<el-table-column prop="reg_Sn" label="序号" align="center">
						</el-table-column>
						<el-table-column prop="patient_Name" label="姓名" align="center">
						</el-table-column>
						<el-table-column label="操作" prop="name" align="center">
              <template slot-scope="scope">
							<el-button type="text" v-on:click="selectuser(scope.row)" style="width:100px; height: 20px;" round>选择
							</el-button>
              </template>
						</el-table-column>
					</el-table-column>
				</el-table>
			</div>
		</el-col>
		<el-col :span="18">
			<div style="background-color: #A6A9AD; font-size: 30px;" class="grid-content bg-purple">
				<span
					style="padding-left: 200px; line-height: 53px;">
					当前患者：{{this.form1.patient_Name}}&nbsp;年龄：{{this.form1.patient_Age}}&nbsp;
					病历号：{{this.form1.patient_ID}}</span>
			</div>
			<div style="display:flex; background-color: #D9D9D9; font-size: 20px;" class="grid-content bg-purple">
				<el-table :data="tableData" max-height="530px" style="width: 100%;" height="530px" border="true"
				@selection-change="handleSelectionChange">
					<el-table-column type="selection" width="45">
					</el-table-column>
					<el-table-column prop="mid" label="药瓶编码">
					</el-table-column>
					<el-table-column prop="mname" label="药瓶名称">
					</el-table-column>
					<el-table-column prop="mspe" label="规格">
					</el-table-column>
					<el-table-column prop="mun" label="包装单位">
					</el-table-column>
					<el-table-column prop="mman" label="生产厂家">
					</el-table-column>
					<el-table-column prop="mp" label="药瓶剂型">
					</el-table-column>
					<el-table-column prop="mtype" label="药品类型">
					</el-table-column>
					<el-table-column prop="munp" label="药品单价">
					</el-table-column>
					<el-table-column prop="mnum" label="开具数量">
					</el-table-column>
					<el-table-column prop="mstate" label="状态">
					</el-table-column>
					<el-table-column prop="mtotal" label="小计">
					</el-table-column>
				</el-table>
			</div>
			<div style="text-align: left; margin: 30px; font-size: 24px;">
				<span>
					<el-button v-on:click="dialogVisible = true" style="width:100px; height: 50px; margin-left: 950px;" round>
						开药
					</el-button>
					<el-dialog
					  title="提示"
					  :visible.sync="dialogVisible"
					  width="30%"
					  :before-close="handleClose"
					  :append-to-body="true">
					  <div>
						  共选药品：{{this.num}}
					  </div>
					  <div>
						  总计金额：{{this.money}}
					  </div>
					  <span slot="footer" class="dialog-footer">
					    <el-button @click="dialogVisible = false">取 消</el-button>
					    <el-button type="button" @click="opendrug()">确 定</el-button>
					  </span>
					</el-dialog>
				</span>
			</div>
		</el-col>
	</el-row>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: false,
				form1: {//上面
					patient_Name: '',
					patient_ID: '',
					patient_Age: ''
				},
				mul: [],
				tableData: [{
          cid: '',
					mid: '',
					mname: '',
					mspe: '',
					mun: '',
					mman: '',
					mp: '',
					mtype: '',
					munp: '',
					mnum: '',
					mstate: '',
					mtotal: ''
				}],
				table: [{//左面
          reg_Sn: '',
					patient_Name: '',
					reg_ID: ''//挂号单号
				}],
				form: {
					patient_Name: ''
				},
				num: 0,
				money: 0
			}
		},
    created () {
      this.loading()
    },
		methods: {
			onSubmit() {
				console.log(this.form)
			},
      async loading(){
        const{data:res} = await this.$http.get('getmedpati')
        this.table = res.pati
      },
			handleSelectionChange(val) {
				this.num = 0
				this.money = 0
				this.mul = val
				for(var i=0;i< this.mul.length;i++)
				{
					this.money += Number(this.mul[i].mtotal)
					this.num += 1
				}
			},
			async selectuser(row){//选择患者时
				const {
				  data: res
				} = await this.$http.post('outpart/selectuser', {"regid":row.reg_ID})
				this.tableData = res.tableData
        console.log(res.form)
        this.form1=res.form
			},
			async Inquiryuser() { //查询患者，输入姓名
				const {
					data: res
				} = await this.$http.post('outpart/getUserTable', {
					"patname": this.form.patient_Name
				})
				this.table = res.table
			},
			async opendrug() {//开药确定
				for(var i=0;i<this.mul.length;i++)
				{
					for(var j=0;j<this.tableData.length;j++)
					{
						if(this.mul[i].mid == this.tableData[j].mid)
						{
							this.tableData[j].mstate = '已完成'
						}
					}
				}
				const {
				  data: res
				} = await this.$http.post('outpart/opendrug', {"med":this.mul})
        if (res.flag == "yes") {
        	this.$message.success("开药成功！");
        }
				this.dialogVisible = false
			}
		}
	}
</script>

<style>
	.uuu .el-form-item__label {
		font-size: 22px !important;
		font-weight: 100px;
		line-height: 40px;
	}
</style>
