<template>
  <el-row :gutter="20">
    <el-col :span="8">
      <div style="background-color: #D9D9D9; font-size: 20px;" class="grid-content bg-purple">
        <div style="padding-left: 40px;padding-top: 10px;">
          中药处方模板
          <el-button v-on:click="onSubmit()" style="width:100px; height: 50px;" round>新建模板</el-button>
        </div>
        <div>
          <el-input type="textarea" style="width:200px; height: 20px;" v-model="form.medRecNum" placeholder="请输入内容"></el-input>
          <el-button v-on:click="onSubmit()" style="width:100px; height: 50px;" round>搜索</el-button>
        </div>
        <div style="padding-left: 5px; padding-right: 5px;padding-top: 15px;">
          <el-table
              ref="multipleTable"
              :data="tableData"
              height="300"
              tooltip-effect="dark"
              border="true"
              @selection-change="handleSelectionChange"
              style="width: 100%">
              <el-table-column
                type="selection"
                width="45">
              </el-table-column>
              <el-table-column
                prop="name"
                label="编号"
                width="110">
              </el-table-column>
              <el-table-column
                prop="name"
                label="名称"
                width="110">
              </el-table-column>
              <el-table-column
                prop="name"
                label="操作"
                width="110">
              </el-table-column>
            </el-table>
        </div>
        <div style="padding-left: 100px;padding-top: 30px;">
          <el-button v-on:click="onSubmit()" style="width:100px; height: 50px;" round>清空</el-button>
        </div>
      </div>
    </el-col>
    <el-col :span="16">
      <div>
        处方详情
        <el-button v-on:click="onSubmit()" style="width:100px; height: 50px;" round>删除该处方</el-button>
      </div>
      <div style="font-size: 25px;">
        多选：
        <el-button v-on:click="onSubmit()" style="width:100px; height: 50px;" round>删除药品</el-button>
        <el-button v-on:click="onSubmit()" style="width:100px; height: 50px;" round>添加药品</el-button>
        <el-button v-on:click="onSubmit()" style="width:100px; height: 50px;" round>修改</el-button>
      </div>
      <div style="padding-left: 20px;">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="300"
            tooltip-effect="dark"
            border="true"
            @selection-change="handleSelectionChange"
            style="width: 100%; padding-left: 20px;">
            <el-table-column
              type="selection"
              width="45">
            </el-table-column>
            <el-table-column
              prop="name"
              label="编号"
              width="90">
            </el-table-column>
            <el-table-column
              prop="name"
              label="药物名称"
              width="90">
            </el-table-column>
            <el-table-column
              prop="name"
              label="用量"
              width="90">
            </el-table-column>
            <el-table-column
              prop="name"
              label="单位"
              width="90">
            </el-table-column>
            <el-table-column
              prop="name"
              label="使用用法"
              width="90">
            </el-table-column>
            <el-table-column
              prop="name"
              label="单价"
              width="90">
            </el-table-column>
            <el-table-column
              prop="name"
              label="频次"
              width="90">
            </el-table-column>
            <el-table-column
              prop="name"
              label="数量"
              width="90">
            </el-table-column>
          </el-table>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  data () {
    return {
      ceshi: [
        {
          value: '一',
          label: '一'
        },
        {
          value: '二',
          label: '二'
        },
        {
          value: '三',
          label: '三'
        }
      ],
      tableData: [{
        medRecNum: 'abfassd',
        name: '王小虎',
        idNum: '上海市普陀区金沙江路 1518 弄',
        payCon: '13元',
        quantity: '数量',
        unitPrice: '单价',
        totalPrice: '总价',
        payState: '缴费状态',
        itemState: '项目状态',
        payTime: '缴费时间'
      }
      ],
      value: ' ',
      form: {
        medRecNum: '',
        name: '',
        sex: '',
        age: '',
        idNum: '',
        dateBirth: '',
        address: '',
        regDepart: '',
        regType: '',
        optDoc: '',
        regCost: ''
      }
    }
  },
  methods: {
    onSubmit () {
      console.log(this.form)
    }
  }
}
</script>

<style>
  .uuu .el-form-item__label
  {
    font-size:22px !important;
    font-weight: 100px;
    line-height: 40px;
  }
</style>
