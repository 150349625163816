import Vue from 'vue'
import VueRouter from 'vue-router'
import myvue1 from '../components/myvue1.vue'
import OaMain from '../components/OaMain.vue'
import siteReg from '../components/前台/siteReg.vue'
import withdnum from '../components/前台/withdnum.vue'
import freeRefund from '../components/前台/freeRefund.vue'
import freeInquiry from '../components/前台/freeInquiry.vue'
import optCases from '../components/门诊医生/optCases.vue'
import wmpTemplate from '../components/门诊医生/wmpTemplate.vue'
import tcmpTemplate from '../components/门诊医生/tcmpTemplate.vue'
import patientMag from '../components/医技医生/patientMag.vue'
import patientTest from '../components/医技医生/patientTest.vue'
import mtechMag from '../components/医技医生/mtechMag.vue'
import drugDelivery from '../components/药房管理/drugDelivery.vue'
import drugWithd from '../components/药房管理/drugWithd.vue'
import drugMag from '../components/药房管理/drugMag.vue'
import departMag from '../components/系统管理/departMag.vue'
import docschMag from '../components/系统管理/docschMag.vue'
import userMag from '../components/系统管理/userMag.vue'
import bingli from '../components/门诊医生/门诊导航标签/bingli.vue'
import jianyan from '../components/门诊医生/门诊导航标签/jianyan.vue'
import caoyao from '../components/门诊医生/门诊导航标签/caoyao.vue'
import chaxun from '../components/门诊医生/门诊导航标签/chaxun.vue'
import chengyao from '../components/门诊医生/门诊导航标签/chengyao.vue'
import chuzhi from '../components/门诊医生/门诊导航标签/chuzhi.vue'
import quezhen from '../components/门诊医生/门诊导航标签/quezhen.vue'
import Login from '../components/登录注册/Login.vue'
Vue.use(VueRouter)

const routes = [{
  path: '/myvue',
  component: myvue1,
  children: [
    {
      path: 'OaMain',
      name: 'OaMain',
      component: OaMain
    },
    {
      path: 'siteReg',
      name: 'siteReg',
      component: siteReg
    },
    {
      path: 'withdnum',
      name: 'withdnum',
      component: withdnum
    },
    {
      path: 'freeInquiry',
      name: 'freeInquiry',
      component: freeInquiry
    },
    {
      path: 'freeRefund',
      name: 'freeRefund',
      component: freeRefund
    },
    {
      path: 'optCases',
      name: 'optCases',
      component: optCases,
      children: [
        {
          path: 'bingli',
          name: 'bingli',
          component: bingli
        },
        {
          path: 'jianyan',
          name: 'jianyan',
          component: jianyan
        },
        {
          path: 'caoyao',
          name: 'caoyao',
          component: caoyao
        },
        {
          path: 'chaxun',
          name: 'chaxun',
          component: chaxun
        },
        {
          path: 'chengyao',
          name: 'chengyao',
          component: chengyao
        },
        {
          path: 'chuzhi',
          name: 'chuzhi',
          component: chuzhi
        },
        {
          path: 'quezhen',
          name: 'quezhen',
          component: quezhen
        }
      ]
    },
    {
      path: 'wmpTemplate',
      name: 'wmpTemplate',
      component: wmpTemplate
    },
    {
      path: 'tcmpTemplate',
      name: 'tcmpTemplate',
      component: tcmpTemplate
    },
    {
      path: 'patientTest',
      name: 'patientTest',
      component: patientTest
    },
    {
      path: 'patientMag',
      name: 'patientMag',
      component: patientMag
    },
    {
      path: 'mtechMag',
      name: 'mtechMag',
      component: mtechMag
    },
    {
      path: 'drugDelivery',
      name: 'drugDelivery',
      component: drugDelivery
    },
    {
      path: 'drugWithd',
      name: 'drugWithd',
      component: drugWithd
    },
    {
      path: 'drugMag',
      name: 'drugMag',
      component: drugMag
    },
    {
      path: 'departMag',
      name: 'departMag',
      component: departMag
    },
    {
      path: 'docschMag',
      name: 'docschMag',
      component: docschMag
    },
    {
      path: 'userMag',
      name: 'userMag',
      component: userMag
    }
  ]
},
{
  path: '/Login',
  name: 'Login',
  component: Login
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
