<template>
  <div style="background-color: #E9EEF3; height: 590px;">
    <div style="overflow:hidden; background-color: #E9EEF3; font-size: 20px;" class="grid-content bg-purple">
      <div style="text-align: left;font-size: 24px; margin: 10px;">患者账单明细：</div>
      <div style="padding-left: 10px; padding-right: 10px; padding-top: 10px; padding-bottom: 10px;">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="460"
            tooltip-effect="dark"
            border="true"
            @selection-change="handleSelectionChange"
            :header-cell-style="{'text-align':'center'}">
            style="width: 100%">
            <el-table-column
              prop="payCon"
              label="名称"
              align="center"
              width="180">
            </el-table-column>
            <el-table-column
              prop="quantity"
              align="center"
              label="数量">
            </el-table-column>
            <el-table-column
              prop="unitPrice"
              align="center"
              label="单价">
            </el-table-column>
            <el-table-column
              prop="totalPrice"
              align="center"
              label="金额">
            </el-table-column>
            <el-table-column
              prop="payState"
              align="center"
              label="状态">
            </el-table-column>
            <el-table-column
              prop="payTime"
              align="center"
              label="时间">
            </el-table-column>
          </el-table>
      </div>
    </div>
    <div style="text-align: left;font-size: 24px; margin: 10px;">
      金额合计：{{this.totalprice}}元
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        payCon: '',
        quantity: '',
        unitPrice: '',
        totalPrice: '',
        payState: '',
        payTime: ''
      }
      ],
      totalprice: ''
    }
  },
  created(){
    this.onSubmit()
  },
  computed: {
    sstate() {
      return this.$store.state.sstate
    }
  },
  watch: {
    sstate (val) {
      this.onSubmit()
    }
  },
  methods: {
    async onSubmit () {
      console.log("123"+this.$store.state.sstate)
      const {
        data: res
      } = await this.$http.post('recption/inq/selectuser',{"regID":this.$store.state.sstate})
	  this.totalprice = res.totalprice
	  this.tableData = res.tableData
    }
  }
}
</script>

<style>
  .uuu .el-form-item__label
  {
    font-size:22px !important;
    font-weight: 100px;
    line-height: 40px;
  }
</style>
