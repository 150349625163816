<template>
	<div>
        <div style=" margin: 10px; text-align: center;"><span style="text-align: left;">科室选择：</span>
        <span style="padding-left: 20px;">
            <el-select v-model="form" placeholder="请选择门诊科室" @change="searchdata()">
                <el-option v-for="item in depart" 
                    :key="item.value" 
                    :label="item.label" 
                    :value="item.value">
                </el-option>
            </el-select>
        </span>
        </div>
        <div style="margin: 10px;">
            <el-table max-height="530px" :data="table.list.slice((this.currentPage-1)*this.pagesize,this.currentPage*this.pagesize)" style="width: 100%">
                <el-table-column label="姓名" width="120" prop="name">
                </el-table-column>
                <el-table-column v-model="check11" label="周一上午">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.check11"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column v-model="check12" label="周一下午">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.check12"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column v-model="check21" label="周二上午">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.check21"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column v-model="check22" label="周二下午">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.check22"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column v-model="check31" label="周三上午">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.check31"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column v-model="check32" label="周三下午">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.check32"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column v-model="check41" label="周四上午">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.check41"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column v-model="check42" label="周四下午">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.check42"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column v-model="check51" label="周五上午">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.check51"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column v-model="check52" label="周五下午">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.check52"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column v-model="check61" label="周六上午">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.check61"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column v-model="check62" label="周六下午">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.check62"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column v-model="check71" label="周日上午">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.check71"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column v-model="check72" label="周日下午">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.check72"></el-checkbox>
                    </template>
                </el-table-column>
            </el-table>
            <el-row>
                <el-col :span="18">
                    <div style="margin: 10px; padding-left: 360px;">
                            <el-pagination
                              @size-change="handleSizeChange"
                              @current-change="handleCurrentChange"
                              :current-page="currentPage"
							  :page-sizes="[5, 10, 20]"
                              :page-size="pagesize"
                              layout="total, sizes, prev, pager, next"
                              :total="table.list.length">
                            </el-pagination>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div style="margin: 10px;">
                        <el-button icon="el-icon-check" type="success" @click="keepdata()">保存</el-button>
                        <el-button icon="el-icon-close" type="danger" @click="canceldata()">取消</el-button>
                    </div>
                </el-col>
            </el-row>
         </div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
                pagesize: 10, //每页多少条
                currentPage: 1, // 当前页
				form: '',
				depart: [{
						label:'label',
						value: 'value',
					}
				],
				table:{
					depID:'',
					list:[{
						id:'',
						name: '',
						check11: '',
						check12: '',
						check21:' ',
						check22:' ',
						check31:' ',
						check32:' ',
						check41:' ',
						check42:' ',
						check51:' ',
						check52:' ',
						check61:' ',
						check62:' ',
						check71:' ',
						check72:' '
					}	
				]
				},
			}
		},created () {
		  this.loading()//加载科室信息
		},
		methods: {
			async loading () {
			  const {
			    data: res
			  } = await this.$http.get('getdocsch')
			  this.depart = res
			},
            handleSizeChange(val) {
                this.pagesize = val;
            },
            handleCurrentChange(val) {
                this.currentPage = val;
            },
			async searchdata() {
				const {
					data: res
				} = await this.$http.post('searchdata',{"depart":this.form} )
				if (res.flag === 'yes') {
					this.$message.success('查询成功！')
				}
				this.table = res.table
			},
			async keepdata() {
				const {
					data: res
				} = await this.$http.post('keepdata', this.table)
				if (res.flag === 'yes') {
					this.$message.success('保存成功！');
				}else{
					this.$message.warning("更新存在问题！")
				}
				this.searchdata();
			},
			async canceldata() {
				const {
					data: res
				} = await this.$http.post('canceldata', this.form.depart)
				this.tableData = res.tableData
			},
			onsubmit() {
				console.log(this.tableData)
			}
		}
	};
</script>

<style>
</style>
