<template>
	<div style="background-color: #E9EEF3; font-size: 20px; height: 570px ;"
		class="grid-content bg-purple">
		<div style=" margin-bottom:20px; margin-left: 20px;margin-right: 20px;">
			<el-row :gutter="20">
				<el-form :rules="rules" ref="form11" :model="form" label-width="0px"
					style="padding-top: 20px;padding-left: 0px;">
					<el-col :span="6">
						<el-form-item class="uuu" label="主诉:" prop="ft_MainSuit" style="text-align: left;">
							<el-input type="textarea" :rows="7" style="width:100%;" v-model="form.ft_MainSuit" placeholder="请输入内容"></el-input>
						</el-form-item>
						<el-form-item class="uuu" prop="ft_IrriHis" label="过敏史:">
							<el-input type="textarea" :rows="7" style="width:100%;" v-model="form.ft_IrriHis"
								placeholder="请输入内容"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item class="uuu" prop="ft_PreMedHis" label="现病史:">
							<el-input type="textarea" :rows="7" style="width:100%;" v-model="form.ft_PreMedHis"
								placeholder="请输入内容"></el-input>
						</el-form-item>
						<el-form-item class="uuu" prop="ft_PhyExam" label="体格检查:">
							<el-input type="textarea" :rows="7" style="width:100%;" v-model="form.ft_PhyExam"
								placeholder="请输入内容"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item class="uuu" prop="ft_PreMedHisCureSitu" label="现病史治疗情况:">
							<el-input type="textarea" :rows="7" style="width:100%;" v-model="form.ft_PreMedHisCureSitu"
								placeholder="请输入内容"></el-input>
						</el-form-item>
						<el-form-item class="uuu" prop="ft_PreDiag" label="初步诊断:">
							<el-input type="textarea" :rows="7" style="width:100%;" v-model="form.ft_PreDiag"
								placeholder="请输入内容"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item class="uuu" prop="ft_PastHis" label="既往史:">
							<el-input type="textarea" :rows="7" style="width:100%;" v-model="form.ft_PastHis"
								placeholder="请输入内容"></el-input>
						</el-form-item>
						<el-form-item class="uuu" prop="ft_ExamSugg" label="检查建议:">
							<el-input type="textarea" :rows="7" style="width:100%;" v-model="form.ft_ExamSugg"
								placeholder="请输入内容"></el-input>
						</el-form-item>
					</el-col>
				</el-form>
			</el-row>
			<div style=" margin: 10px;">
			    <el-button  icon="el-icon-magic-stick" type="warning" @click="jump()" style="width:120px; height: 50px;" round>AI辅诊</el-button>
				<el-button  icon="el-icon-delete" type="danger" @click="resetForm('form11')" style="width:100px; height: 50px;" round>清空</el-button>
				<el-button  icon="el-icon-check" type="primary" v-on:click="addfpage()" style="width:100px; height: 50px;" round>保存</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				value: '',
				userid: "",
				patient_reg_id: "",
				ruleForm: {
					ft_MainSuit: '',
					ft_PreMedHis: '',
					ft_PreMedHisCureSitu: '',
					ft_PastHis: '',
					ft_IrriHis: '',
					ft_PhyExam: '',
					ft_PreDiag: '',
					ft_ExamSugg: ''
				},
				form: {
					ft_ID: '',
					ft_MainSuit: '',
					ft_PreMedHis: '',
					ft_PreMedHisCureSitu: '',
					ft_PastHis: '',
					ft_IrriHis: '',
					ft_PhyExam: '',
					ft_PreDiag: '',
					ft_ExamSugg: ''
				},
				rules: {
					ft_MainSuit: [{
						required: true,
						message: '请输入主诉',
						trigger: 'blur'
					}],
					premedhis: [{
						required: true,
						message: '请输入现病史',
						trigger: 'blur'
					}],
					ft_PreDiag: [{
						required: true,
						message: '请输入初步诊断',
						trigger: 'blur'
					}]
				}
			}
		},
		created() {
			this.loading()
		},
		computed: {
			sstate() {
				return this.$store.state.sstate
			}
		},
		watch: {
			sstate() {
				this.loading()
			}
		},
		methods: {
			oonsubmit() {
				console.log(this.form.ft_ExamSugg)
			},
			async loading() {
				const {
					data: res
				} = await this.$http.post("addbingli", {
					"patient_reg_id": this.$store.state.sstate
				});
				if (res.isAdd == "true") {
					this.$message.success("获取成功！");
					this.dialogVisible = false;
					this.form = res.fpage;
				}
			},
			onSubmit() {},
			resetForm(form1) {
				this.$refs[form1].resetFields();
			},

			jump() {
				var regId = this.$store.state.sstate;
				if(regId == 'a' || regId == 'undefined'){
					this.$message.error("请先选择患者");
					return;
				}

				window.open('https://t-m.cocoaiwork.com/?hospital_code=001&regist_id='+ regId);
			},


			async addfpage() {
				this.form.ft_ID = this.$store.state.sstate;
				const {
					data: res
				} = await this.$http.post("addfpage", this.form);
				if (res.isAdd == "gengxin") {
					this.$message.success("更新成功！");
					this.dialogVisible = false;
				} else if (res.isAdd == "tianjia") {
					this.$message.success("添加成功！");
				} else {
					this.$message.error("保存失败！");
				}
				this.form = res.tableData;
			},
		}
	}
</script>

<style>
	.uuu .el-form-item__label {
		font-size: 22px !important;
		font-weight: 100px;
		line-height: 40px;
		width: 100% !important;
		text-align: left;
	}
</style>
