<template>
	<!-- 公用的内容-->
	<div style="overflow:hidden !important">
		<div style="text-align: left; padding-left: 10px;">
			<span style="font-weight: bolder;">前台&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
			<span>/&nbsp;挂号</span>
		</div>
		<div>
			<el-divider></el-divider>
		</div>
		<div style="text-align: left !important; padding-left: 10px; font-size: 24px;">
			患者基本信息：
		</div>
		<div style="overflow:hidden">
			<el-row>
				<el-form :rules="rules" ref="form" :model="form" label-width="80px" style="padding-top: 20px;">
					<el-col :span="3"><span>&nbsp;</span></el-col>
					<el-col :span="6">
						<div style="text-align: left;">
							<el-form-item label="姓名" class="kk1" prop="name">
								<el-input style="width: 250px;" v-model="form.name" placeholder="请输入内容"></el-input>
							</el-form-item>
							<el-form-item label="身份证号" class="kk1" prop="idNum">
								<el-input style="width: 250px;" v-model="form.idNum" placeholder="请输入内容"
									@blur="getPatient"></el-input>
							</el-form-item>
						</div>
					</el-col>
					<el-col :span="6">
						<div style="text-align: left;">
							<el-form-item label="年龄" class="kk1" prop="age">
								<el-input style="width: 250px;" v-model="form.age" placeholder="请输入内容"></el-input>
							</el-form-item>
							<el-form-item label="出生日期" class="kk1">
								<el-date-picker style="width: 250px;" type="date" placeholder="选择日期"
									v-model="form.dateBirth" value-format="yyyy-MM-dd"></el-date-picker>
							</el-form-item>
						</div>
					</el-col>
					<el-col :span="5">
						<div style="text-align: left;">
							<el-form-item label="性别" class="kk2" prop="sex">
								<el-radio-group v-model="form.sex">
									<el-radio label="男"></el-radio>
									<el-radio label="女"></el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="住址">
								<el-input style="width: 250px;" v-model="form.address" placeholder="请输入内容"></el-input>
							</el-form-item>
						</div>
					</el-col>
					<el-col :span="4">
						&nbsp;
					</el-col>
				</el-form>
			</el-row>
		</div>
		<div>
			<el-divider></el-divider>
		</div>
		<div style="text-align: left !important; padding-left: 10px; font-size: 24px;">
			挂号信息：
		</div>
		<div style="padding-top: 20px; padding-bottom: 20px;">
			<el-row style="margin-top: 10px;">
				<el-form ref="form1" :model="form" label-width="80px">
					<el-col :span="3"><span>&nbsp;</span></el-col>
					<el-col :span="6">
						<div style="text-align: left;">
							<el-form-item label="*挂号科室" class="kkl">
								<el-select style="width: 250px;" filterable v-model="form.regDepart.value"
									@change="typeOnchange()" placeholder="请选择挂号科室">
									<el-option v-for="item in departoptions" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
					</el-col>
					<el-col :span="6">
						<div style="text-align: left;">
							<el-form-item label="*号别" class="kk1">
								<el-select style="width: 250px;" filterable v-model="form.regDepart.regType"
									@change="typeOnchange()" placeholder="请选择号别">
									<el-option v-for="item in Typeoptions" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
					</el-col>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<el-col :span="5">
						<el-form-item label="*门诊医生" class="kk1">
							<el-select style="width: 250px;" filterable v-model="form.regDepart.optDoc"
								@change="docOnchange()" placeholder="请选择门诊医生">
								<el-option v-for="item in Docoptions" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<div style="text-align: left; font-size: 20px;">
							余号：{{this.form.restNum}}
						</div>
					</el-col>
				</el-form>
			</el-row>
		</div>
		<div style="text-align: left !important; padding-left: 200px; font-size: 24px;">
			费用：{{this.form.regCost}}
		</div>
		<div style="text-align: left !important; padding-top: 25px; padding-left: 200px; font-size: 24px;">
			支付方式：
		</div>
		<el-row>
			<el-col :span="3">&nbsp;</el-col>
			<el-col :span="6">
				<div style="padding-left: 50px; padding-top: 25px;">
					<i class="el-icon-my-export"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<i class="el-icon-my-export1"></i>
				</div>
			</el-col>
			<el-col :span="6">&nbsp;</el-col>
			<el-col :span="5">
					<el-button icon="el-icon-upload" type="primary" @click="Onsubmit('fetchdate','form')"
						style="width:100px; height: 50px; margin-top: 40px;" round>
						提交
					</el-button>
			</el-col>
			<el-col :span="4">
				&nbsp;
			</el-col>
		</el-row>
		<el-dialog title="提示" :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
			<div style="text-align: left; font-size: 20px;">
				<div style="margin: 15px;">
					发票号：{{this.form.billNum}}
				</div>
				<div style="margin: 15px;">
					病历号：{{this.form.medRecNum}}
				</div>
				<el-row>
					<div style="margin: 15px;">
						<el-col :span="12">挂号单号：&nbsp;{{this.form.regFormNum}}</el-col>
						<el-col :span="12">序号：&nbsp;{{this.form.seqNum}}</el-col>
					</div>
				</el-row>
				<el-row>
					<div style="margin: 15px;">
						<el-col :span="8">姓名：&nbsp;{{this.form.name}}&nbsp;&nbsp;&nbsp;</el-col>
						<el-col :span="8">年龄：&nbsp;{{this.form.age}} &nbsp;&nbsp;&nbsp;</el-col>
						<el-col :span="8">性别：&nbsp;{{this.form.sex}}</el-col>
					</div>
				</el-row>
				<div style="margin: 15px;">
					身份证号：&nbsp;{{this.form.idNum}}
				</div>
				<el-row>
					<div style="margin: 15px;">
						<el-col :span="12">挂号科室：&nbsp;{{this.form.regDepart.value}}&nbsp;&nbsp;&nbsp;</el-col>
						<el-col :span="12">挂号类别：&nbsp;{{this.form.regDepart.regType}}</el-col>
					</div>
				</el-row>
				<el-row>
					<div style="margin: 15px;">
						<el-col :span="12">医生：&nbsp;{{this.form.regDepart.optDoc}}&nbsp;&nbsp;&nbsp; </el-col>
						<el-col :span="12">挂号日期：&nbsp;{{this.form.regDate}}</el-col>
					</div>
				</el-row>
				<div style="margin: 15px;">
					费用：{{this.form.regCost}}
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="warning" @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="addReg()">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		inject: ['reload'],
		data() {
			const checkNum = (rule, value, callback) => {
				const reg = /^(?:[1-9][0-9]?|1[01][0-9]|150)$/
				if (!reg.test(value)) {
					return callback(new Error('请输入正确的年龄！'))
				}
			}
			const checkID = (rule, value, callback) => {
				const reg =
					/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
				if (!reg.test(value)) {
					return callback(new Error('请输入正确的身份证号!'))
				}
			}
			return {
				docnameshow:'',
				dialogVisible: false,
				departoptions: [{
					label: '11',
					value: '11'
				}, {
					label: '112',
					value: '112'
				}],
				Typeoptions: [{
					label: '专家号',
					value: '专家号'
				}, {
					label: '普通号',
					value: '普通号'
				}],
				Docoptions: [{
					label: '',
					value: ''
				}],
				form: {
					medRecNum: '',
					name: '',
					sex: '',
					age: '',
					idNum: '',
					dateBirth: '',
					address: '',
					regDepart: {
						value: '',
						regType: '',
						optDoc: ''
					},
					regCost: '',
					billNum: '',
					regFormNum: '',
					seqNum: '',
					regDate: '',
					restNum: ''
				},
				rules: {
					name: [{
							required: true,
							message: '名字不能为空！',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 8,
							message: '长度应为2~8位',
							trigger: 'change'
						}
					],
					sex: [{
						required: true,
						message: '性别不能为空！',
						trigger: 'blur'
					}],
					age: [{
							required: true,
							message: '年龄不能为空！',
							trigger: 'blur'
						},
						{
							validator: checkNum,
							trigger: 'blur'
						}
					],
					idNum: [{
							required: true,
							message: '身份证号不能为空！',
							trigger: 'blur'
						},
						{
							validator: checkID,
							trigger: 'blur'
						}
					]
				}
			}
		},
		created() {
			this.getdepart() //页面加载获取所有的科室
		},
		mounted() {

		},
		methods: { //页面加载获取所有的科室
			async getdepart() {
				const {
					data: res
				} = await this.$http.get('reception/regloaddep')
				this.departoptions = res.deps;
			},
			async Onsubmit(str, formName) { //fetchdate为后端请求名
				if (this.form.name == "") {
					this.$message({
						type: 'error',
						message: "请输入患者名称！"
					});
				} else if (this.form.sex == "") {
					this.$message({
						type: 'error',
						message: "请输入患者性别！"
					});
				} else if (this.form.age == "") {
					this.$message({
						type: 'error',
						message: "请输入患者年龄！"
					});
				} else if (this.form.idNum == "") {
					this.$message({
						type: 'error',
						message: "请输入患者身份证号！"
					});
				} else if (this.form.regDepart.value == "") {
					this.$message({
						type: 'error',
						message: "请输入挂号科室！"
					});
				} else if (this.form.regDepart.regType == "") {
					this.$message({
						type: 'error',
						message: "请输入挂号号别！"
					});
				} else if (this.form.regDepart.optDoc == "") {
					this.$message({
						type: 'error',
						message: "请输入挂号医生！"
					});
				} else {
					if (this.form.restNum == '0') {
						this.$message.warning("当前医生没有余号！")
					} else {
						this.dialogVisible = true
						const {
							data: res //获取medRecNum病历号 billNum发票号   regFormNum挂单单号   seqNum序号   regTime(时间非日期)
						} = await this.$http.post("reception/" + str, {
							"idNum": this.form.idNum,
							"optDoc": this.form.regDepart.optDoc
						})
						this.form.billNum = res.billNum
						this.form.regFormNum = res.regFormNum
						this.form.seqNum = res.seqNum
						this.form.regDate = res.regTime
						this.form.medRecNum = res.medRecNum
					}
				}
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						done()
					})
					.catch(_ => {})
			},
			async departOnchange() { //科室改变触发的事件
				const {
					data: res //获取当前科室下的号别
				} = await this.$http.post('reception/SelBydepart', this.form.regDepart.value)
				this.Typeoptions = res
			},
			async typeOnchange() { //号别改变触发的事件
				const {
					data: res //获取当前科室和号别下的医生
				} = await this.$http.post('reception/selbydoc', this.form.regDepart)
				this.Docoptions = res.doc
				this.form.regCost = res.type
			},
			async docOnchange() { //获取当前医生的余号
				const {
					data: res
				} = await this.$http.post('reception/getRgeNumber', this.form.regDepart)
				this.form.restNum = res	
				
			},
			async addReg() { // 确认挂号，真的挂号
				const {
					data: res
				} = await this.$http.post('reception/addReg', this.form)
				if (res.isAdd === 'true') {
					this.$message.success('挂号成功！')
					this.dialogVisible = false
					this.reload(); //刷新页面和数据
				} else {
					this.$message.error('挂号失败！')
				}
				this.dialogVisible = false
			},
			async getPatient() { //当身份证输入框失焦时，就查询病人是否存在
				const {
					data: res
				} = await this.$http.post('reception/getPatient', {
					"idNum": this.form.idNum
				})
				if (res.flag == "false") {
					this.$message.warning("没有该病人的信息！")
				} else {
					this.$message.success("加载成功！")
					this.form.medRecNum = res.patient.patient_ID
					this.form.name = res.patient.patient_Name
					this.form.sex = res.patient.patient_Sex
					this.form.age = res.patient.patient_Age
					this.form.idNum = res.patient.patient_IdNum
					this.form.dateBirth = res.patient.patient_DataBirth
					this.form.address = res.patient.patient_Address
				}
			}
		}
	}
</script>

<style>
	.kk1 .el-form-item__label {
		text-align: left;
	}

	.kk2 .el-form-item__label {
		text-align: left;
		width: 60px !important;
	}

	.kk3 .el-form-item__label {
		text-align: left;
		width: 40px !important;
	}

	.el-icon-my-export {
		background: url(../../assets/image/支付宝.png) center no-repeat;
		background-size: cover;
	}

	.el-icon-my-export:before {
		content: "替";
		font-size: 85px;
		visibility: hidden;
	}

	.el-icon-my-export1 {
		background: url(../../assets/image/微信.png) center no-repeat;
		background-size: cover;
	}

	.el-icon-my-export1:before {
		content: "替";
		font-size: 85px;
		visibility: hidden;
	}
</style>
