<template>
	<div>
		<!-- 办公自动化页面布局-->
		<el-container class="bcd">
			<el-header>
				<div
					style="float: left !important; padding-left:40px !important; padding-top: 10px; text-align: center;">
					<el-avatar shape="square"
						src="https://img1.baidu.com/it/u=379226345,3276899612&fm=26&fmt=auto&gp=0.jpg">
					</el-avatar>
					<div class="dd" style="padding-left:8px !important; float:right; line-height:40px; height:40px;">
						HIS管理系统</div>
				</div>
				<el-dropdown style="float: right !important; padding-right:20px !important; padding-top: 10px;">
					<span class="el-dropdown-link">
						<el-avatar src="https://img1.baidu.com/it/u=3701867600,1979122419&fm=26&fmt=auto&gp=0.jpg">
						</el-avatar>
                        <span class="dd" style="background-color: #324057;">{{username}}</span>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item>
							<el-button type="text" style="width:100px; height: 50px;" round>账户设置</el-button>
						</el-dropdown-item>
						<el-dropdown-item>
							<el-button type="text" @click="logout()" style="width:100px; height: 50px;" round>安全退出
							</el-button>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</el-header>

			<el-container class="abc">
				<el-aside style="width:200px;">
					<el-row class="tac">
						<el-col :span="24">
							<el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen"
								@close="handleClose" background-color="#ffffff" text-color="#000000"
								active-text-color="#3A8EE6" :unique-opened="true" :router="true">
								<el-submenu :index="item.id+'f'" v-for="item in menuList" :key="item.id">
									<template slot="title">
										<i class="el-icon-location"></i>
										<span>{{item.title}}</span>
									</template>
									<el-menu-item-group>
										<!--<template slot="title">分组一</template>-->
										<!-- <router-link :to="{name:''}"> -->
											<el-menu-item :index="it.path" v-for="it in item.sList" :key="it.id" >
												<i class="el-icon-location"></i>
												{{it.title}} 
											</el-menu-item>
										<!-- </router-link> -->
									</el-menu-item-group>
								</el-submenu>
							</el-menu>
						</el-col>
					</el-row>
				</el-aside>
				<el-main>
					<router-view v-if="isRouterAlive"></router-view>
					
					<div class="bounce" v-show="helloImg == 1">
						<font color="#000000" class="letter">医</font>
						<font color="#000000" class="letter">院</font>
						<font color="#000000" class="letter">门</font>
						<font color="#000000" class="letter">诊</font>
						<font color="#000000" class="letter">信</font>
						<font color="#000000" class="letter">息</font>
						<font color="#000000" class="letter">管</font>
						<font color="#000000" class="letter">理</font>
						<font color="#000000" class="letter">系</font>
						<font color="#000000" class="letter">统</font>
					</div>
					
				</el-main>
			</el-container>
            
			<el-footer>
				<div class="cc" style="float:right !important; padding-right: 40px;">问诊宝医疗</div>
			</el-footer>
		</el-container>
	</div>
</template>

<script>
	export default {
		provide () {    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
		    return {
		        reload: this.reload ,
		    }
		},
		data() {
			return {
				helloImg:1,
				username: '无用户',
				userid:'',
				menuList: [], //菜单列表
				isRouterAlive: true                    //控制视图是否显示的变量
                
			}
		},
		created() { //页面一加载就加载菜单列表
			this.getMenuList();
		},
		mounted() {
			this.username = JSON.parse(window.sessionStorage.getItem("user")).name;
		},
		methods: {
			handleOpen(key, keyPath) {
				this.helloImg = 0;
				console.log(key, keyPath)
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath)
			},
			logout() {
				window.sessionStorage.clear();
				this.$router.push("/login");
			},
			reload () {
			    this.isRouterAlive = false;            //先关闭，
			    this.$nextTick(function () {
			        this.isRouterAlive = true;         //再打开
			    }) 
			},
			async getMenuList() { //获取导航菜单
							this.userid = JSON.parse(window.sessionStorage.getItem("user")).systype;			
							const{data:res} = await this.$http.post("menus",{"userid":this.userid});
							this.menuList = res.menus;
				},

		}
	}
</script>

<style>
	* {
		margin: 0px;
		padding: 0px;
		text-decoration: none;
		list-style: none;
	}
     
    html,body {
      width: 100%;
      height: 100%;
      margin: 0;
      overflow-y: hidden;
    }
	
    .bounce {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    	color: white;
      height: 100%;
      font: normal bold 6rem "Product Sans", sans-serif;
      white-space: nowrap;
    }
    
    .letter {
      animation: bounce 0.75s cubic-bezier(0.05, 0, 0.2, 1) infinite alternate;
      display: inline-block;
      transform: translate3d(0, 0, 0);
      margin-top: 0.5em;
      text-shadow: rgba(0, 0, 0, 0) 0 0 0.05em;
      font: normal 500 6rem 'Varela Round', sans-serif;
    }
    .letter:nth-child(1) {
      animation-delay: 0s;
    }
    .letter:nth-child(2) {
      animation-delay: 0.0833333333s;
    }
    .letter:nth-child(3) {
      animation-delay: 0.1666666667s;
    }
    .letter:nth-child(4) {
      animation-delay: 0.25s;
    }
    .letter:nth-child(5) {
      animation-delay: 0.3333333333s;
    }
    .letter:nth-child(6) {
      animation-delay: 0.4166666667s;
    }
    
    
    @keyframes bounce {
      0% {
        transform: translate3d(0, 0, 0);
        text-shadow: rgba(0, 0, 0, 0.4) 0 0 0.05em;
      }
      100% {
        transform: translate3d(0, -1em, 0);
        text-shadow: rgba(0, 0, 0, 0.4) 0 1em 0.35em;
      }
    }
    
	.cc {
		color: #FFFFFF;
	}

	.dd {
		color: #FFFFFF;
	}

	a {
		text-decoration: none;
		color: #000000;
	}

	.bbb .el-submenu {
		float: right !important;
	}

	.el-menu-item {
		float: right !important;
	}

	.router-link-exact-active {
		color: #3A8EE6;
	}

	.tac {
		width: 100%;
		text-align: left;
	}

	.el-header {
		line-height: 60px;
		background-color: #324057;
		padding-left: 0px !important;
		padding-right: 0px !important;
	}

	.el-aside {
		width: 400px;
		background-color: #FFFFFF;
		height: 807px;
	}

	.el-footer {
		line-height: 40px !important;
		height: 40px !important;
		background-color: #324057;
	}

	.el-main {
		background-color: #FBFDFF;
		padding: 30px !important;
		box-shadow: 10px 10px 5px #888888 !important;
		margin: 20px;
	}

	.statue .el-col {
		background-color: #13CE66;
		height: 80px;
		width: 100px;
	}
</style>
