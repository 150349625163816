<template>
	<el-row :gutter="20">
		<el-col :span="18">
			<div style="background-color: #B3C0D1; font-size: 24px;" class="grid-content bg-purple">
				<el-row>
					<el-col :span="6" style="line-height: 48px;">当前患者：{{this.table.patient_Name}}</el-col>
					<el-col :span="6" style="line-height: 48px;">年龄：{{this.table.patient_Age}}</el-col>
					<el-col :span="6" style="line-height: 48px;">病历号：{{this.table.patient_ID}}</el-col>
					<el-col :span="6" style="line-height: 48px; padding-bottom: 5px;">
						<el-button type="primary" @click="onSubmit()" style="width:100px;" plain>诊毕</el-button>
					</el-col>
				</el-row>
			</div>
			<div style="display:flex; background-color: #E9EEF3; font-size: 20px;" class="grid-content bg-purple">
				<div style="background-color: #E9EEF3; font-size: 20px;" class="grid-content bg-purple">
				
						<router-link :to="{name:'bingli'}" class="button button1">
							病历首页
						</router-link>

						<router-link :to="{name:'jianyan'}" class="button button1">
							检验申请
						</router-link>
		
						<router-link :to="{name:'quezhen'}" class="button button1">
							门诊确诊
						</router-link>
		
						<router-link :to="{name:'chengyao'}" class="button button1">
							处方
						</router-link>

						<router-link :to="{name:'chaxun'}" class="button button1" style="border-right: 2px solid #73c7ff;">
							费用查询
						</router-link>

				<router-view></router-view>
				</div>
			</div>
		</el-col>
		<el-col :span="6">
			<div style="height: 90px; overflow:hidden; background-color: #f5f7fa; border-radius: 2px;"
				class="grid-content bg-purple">
				<el-form ref="form" :model="form" label-width="80px" style="padding-top: 20px;">
					<div style="float: left; background-color: #f5f7fa;">
						<el-form-item label="姓名">
							<el-input style="width:150px;" v-model="form.patient_Name" placeholder="请输入内容"></el-input>
							&nbsp;&nbsp;&nbsp;
							<el-button icon="el-icon-search" type="primary" v-on:click="Inquiryuser()"
								style="width:100px; height: 40px;" round>查询
							</el-button>
						</el-form-item>
					</div>
				</el-form>
			</div>
			<div style="margin: 0px;   border-radius: 2px; height: 270px;">
				<el-table :data="tableData" max-height="270px" style="width: 100%;   border-radius: 2px;"
					:header-cell-style="{'text-align':'center'}">
					<el-table-column label="未诊患者">
						<el-table-column prop="reg_Sn" label="序号" width="125" align="center">
						</el-table-column>
						<el-table-column prop="patient_Name" label="姓名" width="125" align="center">
						</el-table-column>
						<el-table-column label="操作" width="126" align="center">
							<template slot-scope="scope">
								<el-button @click="selectRow(scope.row)" type="text" size="small">
									选择
								</el-button>
							</template>
						</el-table-column>
					</el-table-column>
				</el-table>
			</div>
			<div style="margin: 0px; margin-top: 52px;  border-radius: 2px;">
				<el-table :data="table" max-height="280px" style="width: 100%; border-radius: 2px;"
					:header-cell-style="{'text-align':'center'}">
					<el-table-column label="已诊患者">
						<el-table-column prop="reg_Sn" label="序号" width="125" align="center">
						</el-table-column>
						<el-table-column prop="patient_Name" label="姓名" width="125" align="center">
						</el-table-column>
						<el-table-column label="操作" width="126" align="center">
							<template slot-scope="scope">
								<el-button @click="selectRow(scope.row)" type="text" size="small">
									选择
								</el-button>
							</template>
						</el-table-column>
					</el-table-column>
				</el-table>
			</div>
		</el-col>
	</el-row>
</template>

<script>
	export default {
		data() {
			return {
				docid: '',
				tableData: [{
					reg_Sn: '',
					patient_Name: '',
					reg_ID: ''
				}],
				form: {
					patient_Name: ''
				},
				table: [{
					reg_Sn: '',
					patient_Name: '',
					reg_ID: ''
				}]
			}
		},
		created() {
			this.loading()
		},
		methods: {
			async comPlate() {
				const {
					data: res
				} = await this.$http.post("comPlate", {
					"docid": this.docid,
					"patname": this.form.patient_Name
				});
				this.tableData = res.patiswz
				this.table = res.patisyz
			},
			async loading() {
				this.docid = JSON.parse(window.sessionStorage.getItem("user")).id;
				const {
					data: res
				} = await this.$http.post('loading', {
					"docid": this.docid
				})

				this.tableData = res.patiswz
				this.table = res.patisyz
			},
			async onSubmit() { //诊毕
			if(this.$store.state.sstate.length<5){
				this.$message.info("不可操作！")
			}else{
				const {
					data: res
				} = await this.$http.post('outdepart/dia/quezhen', {"regID":this.$store.state.sstate})
				if(res.flag=="true"){
					this.$message.success("操作成功！")
					this.loading()
				}
			}
			},
			async Inquiryuser() { //查询患者，输入姓名
				const {
					data: res
				} = await this.$http.post('getUserTable', {
					"docid": this.docid,
					"patname": this.form.patient_Name
				})
				this.tableData = res.patisbydpwz
				this.table = res.patisbydpyz
			},
			async selectRow(rows) {
				const {
					data: res
				} = await this.$http.post('getUserdata', {
					"reg_ID": rows.reg_ID
				})
				this.table = res.paups
				this.$store.state.sstate = rows.reg_ID
			}
		}
	}
</script>

<style>
	.gg {
		border-radius: 30px;
	}

	.uuu .el-form-item__label {
		font-size: 22px !important;
		font-weight: 100px;
		line-height: 40px;
	}

	.button {
		background-color: white;
		border: none;
		width: 300px !important;
		height: 40px;
		color: white;
		text-align: center;
		text-decoration: none;
		font-size: 16px;
		background: paleturquoise;
		vertical-align: middle;
		display: table-cell;
		margin: 1px 1px;
		-webkit-transition-duration: 0.4s;
		/* Safari */
		transition-duration: 0.4s;
		cursor: pointer;
	}

	.button1 {
		background-color: white;
		border-left: 2px solid #73c7ff;
		border-top: 2px solid #73c7ff;
		border-bottom: 2px solid #73c7ff;
		color: #333;
	}

	.button1:hover {
		background-color: #73c7ff;
		color: white;
	}
</style>
