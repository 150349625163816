<template>
	<el-row :gutter="20">
		<el-col :span="18">
			<div style="background-color: #B3C0D1; font-size: 30px;" class="grid-content bg-purple">
				<span
					style="padding-left: 200px; line-height: 53px;">当前患者：{{this.table.patient_Name}}&nbsp;年龄：{{this.table.patient_Age}}&nbsp;病历号：{{this.table.patient_ID}}</span>
			</div>
			<div>
				<div style="text-align: left !important; margin: 10px; font-size: 24px;">
					检验信息：
				</div>
				<div style="margin: 10px;">
					<el-table ref="multipleTable" :data="magtable" height="500" max-height="500" tooltip-effect="dark"
						border="true" @selection-change="handleSelectionChange" style="width: 100%;">
						<el-table-column type="selection" width="45">
						</el-table-column>
						<el-table-column prop="name" label="检验名称">
						</el-table-column>
						<el-table-column prop="num" label="数量">
						</el-table-column>
						<el-table-column prop="unp" label="单价">
						</el-table-column>
						<el-table-column prop="totalp" label="总价">
						</el-table-column>
						<el-table-column prop="state" label="状态">
						</el-table-column>
						<el-table-column label="上传">
							<template slot-scope="scope">
								<span v-if="scope.row.state ==='已检验'"><el-button @click="dialogVisible = true">上传</el-button></span>
								<span v-else><el-button disabled>上传</el-button></span>
							</template>
						</el-table-column>
					</el-table>
					<el-dialog
					  title="提示"
					  :visible.sync="dialogVisible"
					  width="30%"
					  :before-close="handleClose"
					  :append-to-body="true">
					  <div>
						  gdthfygjuhkjl
					  </div>
					  <span slot="footer" class="dialog-footer">
					    <el-button @click="dialogVisible = false">取 消</el-button>
					    <el-button type="button" @click="dialogVisible = false">确 定</el-button>
					  </span>
					</el-dialog>
				</div>
				<div style="float:right !important; padding-right: 280px; padding-top: 35px;">
					<el-button v-on:click="onSubmit()" style="width:100px; height: 50px;" round>确定检验</el-button>
				</div>
			</div>
		</el-col>
		<el-col :span="6">
			<div style="height: 90px; overflow:hidden; background-color: #f5f7fa; border-radius: 2px;"
				class="grid-content bg-purple">
				<el-form ref="form" :model="form" label-width="80px" style="padding-top: 20px;">
					<div style="float: left; background-color: #f5f7fa;">
						<el-form-item label="姓名">
							<el-input style="width:150px;" v-model="form.patient_Name" placeholder="请输入内容"></el-input>
							&nbsp;&nbsp;&nbsp;
							<el-button v-on:click="Inquiryuser()" style="width:100px; height: 40px;" round>查询
							</el-button>
						</el-form-item>
					</div>
				</el-form>
			</div>
			<div style="margin-bottom: 60px;">
				<el-table :data="tableData" max-height="512px" style="width: 100%;"
					:header-cell-style="{'text-align':'center'}" height="512px">
					<el-table-column label="检验">
						<el-table-column prop="reg_Sn" label="序号" align="center">
						</el-table-column>
						<el-table-column prop="patient_Name" label="姓名" align="center">
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-button @click="selectRow(scope.row)" type="text" size="small">
									选择
								</el-button>
							</template>
						</el-table-column>
					</el-table-column>
				</el-table>
			</div>
		</el-col>
	</el-row>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: false,
				multipleTable: [],
				magtable: [{
          id: '',
					name: '',
					num: '',
					unp: '',
					totalp: '',
					state: ''
				}],
				regid: '',
				tableData: [{//未检验
					reg_Sn: '',
					patient_Name: '',
					reg_ID: ''
				}],
				form: {
					patient_Name: ''
				},
				table: {//头
					patient_Name: '',
					patient_Age: '',
					patient_ID: ''
				}
			}
		},
		created() {
			this.loading()
		},
  computed: {
    sstate() {
      return this.$store.state.sstate
    }
  },
  watch: {
    sstate (val) {
      this.loading()
    }
  },
		methods: {
			async loading() {
				const {
					data: res
				} = await this.$http.get('loadingtech')
				this.tableData = res.table
			},
			async onSubmit() { //确定检验
				for(var i=0;i<this.multipleTable.length;i++)
				{
					for(var j=0;j<this.magtable.length;j++)
					{
						if(this.multipleTable[i].name == this.magtable[j].name)
						{
							this.magtable[j].state = '已完成'
						}
					}
				}
				const {
				  data: res
				} = await this.$http.post('conftest', {"tech":this.multipleTable,"regid":this.regid})
				if(res.flag === "yes")
				{
					this.$message.success("检验成功！")
				}
				else
				{
					this.$message.error("检验失败！")
				}
			},
			async Inquiryuser() { //查询患者，输入姓名
				const {
					data: res
				} = await this.$http.post('patient/getUserTable', {
					"patname": this.form.patient_Name
				})
				this.tableData = res.patisbydpwz
			},
			handleSelectionChange (val) {
			  this.multipleTable = val
			},
			async selectRow(row) {//选择患者
				const {
					data: res
				} = await this.$http.post('selecttechpat', {
					"reg_ID": row.reg_ID
				})
        this.regid=row.reg_ID
				this.table = res.tables
        this.magtable=res.magtables
			}
		}
	}
</script>

<style>
	.uuu .el-form-item__label {
		font-size: 22px !important;
		font-weight: 100px;
		line-height: 40px;
		width: 100% !important;
		text-align: left;
	}
</style>
