<template>
	<el-row :gutter="20" style="background-color: #E9EEF3; margin: 2px;margin-top: 0px; height: 580px;">
		<div style="font-size: 24px; text-align: left; margin: 10px; background-color: #E9EEF3;">
			<span>门诊诊断：</span>
			<span style="border-radius: 30px; border-width:1px; border-color: #000000; border-style:solid;"
				v-for="index in dis" v-bind:key="index">
				<span style="margin: 10px; margin-right: 15px;">
					{{ index }}
				</span>
			</span>
		</div>
		<el-col :span="6">
			<div style="background-color: #B3C0D1; font-size: 20px;" class="grid-content bg-purple">
				<div style="padding-left: 0px; padding-top: 10px; background-color: #E9EEF3; display: flex;">
					<el-button icon="el-icon-plus" type="success" @click="dialogVisible = true"
						style="margin-bottom: 10px; width:125px; height: 50px;" round>增加处方</el-button>
					<el-dialog title="增加处方" :visible.sync="dialogVisible" width="28%" :append-to-body="true"
						:before-close="handleClose">
						<div style=" margin-left:100px ;">
							门诊处方：
							<el-input style="width:230px" v-model="childtableData.name" placeholder="请输入门诊处方">
							</el-input>
						</div>
						<span slot="footer" class="dialog-footer">
							<el-button type="warning" @click="dialogVisible = false">取 消</el-button>
							<el-button type="primary" v-on:click="addchufang()">确 定</el-button>
						</span>
					</el-dialog>
					<el-button icon="el-icon-delete" type="danger" v-on:click="delectchufang()"
						style="width:125px; height: 50px; margin-left: 30px;" round>删除处方</el-button>
				</div>
				<div style="margin: 10px;">
					<div style="text-align: left; margin-bottom: 15px;">
						门诊处方
					</div>
					<el-table ref="multipleTable" :data="tableData" height="300" tooltip-effect="dark" border="true"
						:header-cell-style="{'text-align':'center'}" @selection-change="handleSelectionChange"
						style="width: 100%">
						<el-table-column type="selection" align="center" width="50">
						</el-table-column>
						<el-table-column label="名称" prop="name" align="center">
							<template slot-scope="scope">
								<el-button type="text" @click="selectchufang(scope.row.id)">{{scope.row.name}}
								</el-button>
							</template>
						</el-table-column>
						<el-table-column prop="state" label="状态" align="center">
						</el-table-column>
					</el-table>
				</div>
				<div style="padding-left: 150px;padding-top: 0px;">
					<span v-if="kailistate === true">
						<el-button icon="el-icon-check" type="primary" v-on:click="openchufang()"
							style="width:100px; height: 50px; margin-bottom: 10px;" round>开立</el-button>
					</span>
					<span v-else>
						<el-button v-on:click="openchufang()" style="width:100px; height: 50px; margin-bottom: 10px;"
							round disabled>开立</el-button>
					</span>
				</div>
			</div>
		</el-col>
		<el-col :span="18">
			<div style="font-size: 24px; text-align: left; padding-top: 5px;">
				<span style="line-height: 50px; text-align: left; padding-left: 15px;">本处方金额一共：{{this.money}}元</span>
				<span style="padding-left: 340px;">
					<el-button icon="el-icon-plus" type="success" @click="dialogVisible1 = true"
						style=" width:100px; height: 50px;" round>增药</el-button>
				</span>
				<el-dialog title="增药" :visible.sync="dialogVisible1" width="41%" :append-to-body="true"
					:before-close="handleClose">
					<div>
						药物名称：
						<el-input v-model="depart" style="width: 400px;" placeholder="请输入内容"></el-input>
						<span style="padding-left: 50px;">
							<el-button type="primary" v-on:click="finddrug()" style="width:100px; margin-bottom: 17px;"
								round>
								查询
							</el-button>
						</span>
					</div>
					<div style="padding-bottom: 10px;">
						药物信息：
					</div>
					<el-table ref="multipleTable" :data="childtable" height="320" tooltip-effect="dark" border="true"
						@selection-change="handleSelectionChange" :header-cell-style="{'text-align':'center'}">
						<el-table-column prop="id" label="药瓶编码" align="center">
						</el-table-column>
						<el-table-column prop="name" label="药瓶名称" align="center">
						</el-table-column>
						<el-table-column prop="spec" label="药物规格" align="center">
						</el-table-column>
						<el-table-column prop="un" align="center" label="包装单位">
						</el-table-column>
						<el-table-column prop="manu" label="生产厂家" align="center">
						</el-table-column>
						<el-table-column prop="meditype" label="药瓶剂型" align="center">
						</el-table-column>
						<el-table-column prop="type" label="药瓶类型" align="center">
						</el-table-column>
						<el-table-column prop="up" label="单价" align="center">
						</el-table-column>
						<el-table-column align="center" label="操作">
							<template slot-scope="scope">
								<el-button type="text" @click="selectdrug(scope.row)">选择</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div style="padding-top: 10px; padding-bottom: 10px;">
						已选择：{{this.fform.name}}
					</div>
					<span slot="footer" class="dialog-footer">
						<el-button type="warning" @click="dialogVisible1 = false">取 消</el-button>
						<el-button type="primary" v-on:click="adddrug()">确 定</el-button>
					</span>
				</el-dialog>
				<span style="padding-left: 30px;padding-right: 20px;">
					<el-button icon="el-icon-delete" type="danger" v-on:click="delectdrug()"
						style="width:100px; height: 50px;margin-bottom: 10px;" round>删药</el-button>
				</span>
			</div>
			<div style="margin-right: 20px;margin-left: 20px;">
				<el-table ref="multipleTable" :data="table" height="284" max-height="284" tooltip-effect="dark"
					border="true" @selection-change="handleSelectionChange1"
					:header-cell-style="{'text-align':'center'}">
					<el-table-column type="selection" align="center" width="45">
					</el-table-column>
					<el-table-column prop="name" label="药瓶名称" align="center">
					</el-table-column>
					<el-table-column prop="spec" label="药物规格" align="center">
					</el-table-column>
					<el-table-column prop="un" align="center" label="包装单位">
					</el-table-column>
					<el-table-column prop="manu" label="生产厂家" align="center">
					</el-table-column>
					<el-table-column prop="type" label="药瓶类型" align="center">
					</el-table-column>
					<el-table-column prop="fre" label="频次" align="center">
					</el-table-column>
					<el-table-column prop="up" label="单价" align="center">
					</el-table-column>
					<el-table-column prop="num" align="center" label="数量">
					</el-table-column>
					<el-table-column prop="total" align="center" label="小计">
					</el-table-column>
				</el-table>
			</div>
			<el-form ref="form" :model="form" label-width="160px"
				style="overflow:hidden; padding-top: 20px; float: left; padding-left: 20px;">
				<div style="text-align: left; font-size: 24px;">
					输入服法用量：
				</div>
				<div style="padding-top:15px ;">
					<el-input type="textarea" :rows="3" style="width:840px;" v-model="form.medRecNum"
						placeholder="请输入内容"></el-input>
				</div>
			</el-form>
		</el-col>
	</el-row>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					medRecNum: ''
				},
				money:'' ,
				depart: '',
				kailistate: true,
				fform: {
					name: '',
					num: '',
					fre: '',
					id: '',
					cid: ''
				},
				dialogVisible1: false,
				dialogVisible: false,
				userid: '',
				docid: '',
				dis: [],
				tableData: [{
					id: '',
					name: '',
					state: ''
				}],
				childtableData: {
					id: '',
					name: ''
				},
				childtablename: [{
					id: '',
					name: ''
				}],
				cunchu: [],
				cunchu1: [],
				value: ' ',
				table: [{
					id: '',
					name: '',
					spec: '',
					un: '',
					manu: '',
					type: '',
					fre: '',
					up: '',
					num: '',
					total: ''
				}],
				childtable: [{
					id: '',
					name: '',
					spec: '',
					un: '',
					manu: '',
					meditype: '',
					type: '',
					up: ''
				}]
			}
		},
		created() { //页面一加载就读取
			this.getAlldata();
		},
		computed: {
			sstate() {
				return this.$store.state.sstate
			}
		},
		watch: {
			sstate() {
				this.getAlldata()
			}
		},
		methods: {
			async getAlldata() { //初始化
				// const {
				// 	data: res
				// } = await this.$http.post('getAlldata', {
				// 	"regID": this.$store.state.sstate
				// })
				// this.dis = res.dis
				// this.tableData = res.tableData
			},
			handleSelectionChange(val) {
				this.cunchu = val
				for (var i = 0; i < this.cunchu.length; i++) {
					if (this.cunchu.state === '已开立') {
						this.kailistate = false
					}
				}
			},
			handleSelectionChange1(val) {
				this.cunchu1 = val
			},
			async selectchufang(id) { //点击处方
				const {
					data: res
				} = await this.$http.post("selectchufang", {
					"preid": id
				});
				this.table = res.table
				this.fform.cid = id
				this.money = res.money
			},
			async delectdrug() { //删除处方的药品
				const {
					data: res
				} = await this.$http.post("delectdrug", {
					"cfid": this.fform.cid,
					"med": this.cunchu1
				});
				if (res.flag == "yes") {
					this.$message.success("删药成功！");
				} else {
					this.$message.error("删药失败！");
				}
				this.table = res.table
			},
			async adddrug() { //处方添加药品，点确定
				const {
					data: res
				} = await this.$http.post("adddrug", {
					"med": this.fform
				});
				if (res.flag == "yes") {
					this.$message.success("增药成功！");
				} else {
					this.$message.error("增药失败！");
				}
				this.table = res.table
				this.dialogVisible1 = false
			},
			async finddrug() { //查询药品名
				const {
					data: res
				} = await this.$http.post("finddrug", {
					"medname": this.depart
				});
				if (res.flag == "yes") {
					this.$message.success("查询成功！");
				} else {
					this.$message.error("查询失败！");
				}
				this.childtable = res.childtale
			},
			selectdrug(row) { //选择药品
				this.fform.name = row.name
				this.fform.id = row.id
			},
			async openchufang() { //开立处方
				const {
					data: res
				} = await this.$http.post("openchufang", {
					"regID": this.$store.state.sstate,
					"table": this.cunchu
				});
				if (res.flag == "yes") {
					this.$message.success("开立成功！");
				} else {
					this.$message.error("开立失败！");
				}
				this.tableData = res.tableData
				this.dialogVisible = false
			},
			async delectchufang() { //删除处方
				const {
					data: res
				} = await this.$http.post("delectchufang", {
					"regID": this.$store.state.sstate,
					"table": this.cunchu
				});
				if (res.flag == "yes") {
					this.$message.success("删除成功！");
				} else {
					this.$message.error("删除失败！");
				}
				this.tableData = res.tableData
			},
			async addchufang() { //添加处方
				const {
					data: res
				} = await this.$http.post("addchufang", {
					"regID": this.$store.state.sstate,
					"name": this.childtableData.name
				});
				if (res.flag == "yes") {
					this.$message.success("添加成功！");
				} else {
					this.$message.error("添加失败！");
				}
				this.tableData = res.tableData
				this.dialogVisible = false
			},
			onSubmit() {
				console.log(this.form)
			}
		}
	}
</script>

<style>
	.uuu .el-form-item__label {
		font-size: 22px !important;
		font-weight: 100px;
		line-height: 40px;
	}
</style>
