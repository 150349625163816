<template>
  <!-- 公用的内容-->
  <div style="overflow:hidden !important">
    <div style="overflow:hidden">
      <el-form ref="form" :model="form" label-width="80px" style="padding-top: 20px;">
        <div style="float: left; padding-left: 200px;">
          <el-form-item label="" class="kkk">
            <el-input v-model="form.techItem" placeholder="请输入医技名称"></el-input>
          </el-form-item>
          <el-button icon="el-icon-search" type="primary" v-on:click="Inquiry()" style="width:100px; height: 50px;" round>搜索</el-button>
          <el-button icon="el-icon-plus" type="success" @click="dialogVisible = true" style="width:120px; height: 50px;" round>新增医技</el-button>
          <el-dialog title="提示" :visible.sync="dialogVisible" width="40%" :append-to-body="true"
            :before-close="handleClose">

            <div style=" margin-left:100px ;">
              <el-form ref="form1" :model="childform" label-width="80px" style="padding-top: 20px;">
                <div style="">
                  <el-form-item label="医技编码" class="kkk">
                    <el-input v-model="childform.techNum" placeholder="请输入内容"></el-input>
                  </el-form-item>
                  <el-form-item label="项目名称" class="kkk">
                    <el-input v-model="childform.techItem" placeholder="请输入内容"></el-input>
                  </el-form-item>
                </div>
                <div style="">
                  <el-form-item label="执行科室" class="kkk">
                    <el-input v-model="childform.techExeOff" placeholder="请输入内容"></el-input>
                  </el-form-item>
                  <el-form-item label="医技类型" class="kkk1">
                    <el-select v-model="childform.techType" placeholder="请选择医技类型">
                      <el-option label="检验" value="0"></el-option>
                      <el-option label="处置" value="1"></el-option>
                      <el-option label=""></el-option>
                    </el-select>
                </el-form-item>
                </div>
                <div style="">
                  <el-form-item label="项目单价" class="kkk">
                    <el-input v-model="childform.techUnitPrice" placeholder="请输入内容"></el-input>
                  </el-form-item>
                </div>
              </el-form>
            </div>

            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" v-on:click="addDepart()">确定添加</el-button>
            </span>
          </el-dialog>
        </div>
      </el-form>
    </div>
    <div>
      <el-divider></el-divider>
    </div>
    <div style="overflow:hidden">
      <el-table
          ref="multipleTable"
          :data="tableData"
          height="300"
          tooltip-effect="dark"
          border="true"
          style="width: 100%; padding-left: 20px;">
          <el-table-column
            prop="techNum"
            label="医技编码"
            width="200">
          </el-table-column>
          <el-table-column
            prop="techItem"
            label="项目名称"
            width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.show">
                <el-input size="mini" v-model="scope.row.techItem"></el-input>
              </span>
              <span v-else>{{ scope.row.techItem }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="techType"
            label="项目类型"
            width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.show">
                <el-input size="mini" v-model="scope.row.techType"></el-input>
              </span>
              <span v-else>{{ scope.row.techType }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="techExeOff"
            label="执行科室"
            width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.show">
                <el-input size="mini" v-model="scope.row.techExeOff"></el-input>
              </span>
              <span v-else>{{ scope.row.techExeOff }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="techUnitPrice"
            label="单价"
            width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.show">
                <el-input size="mini" v-model="scope.row.techUnitPrice"></el-input>
              </span>
              <span v-else>{{ scope.row.techUnitPrice }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="200">
            <template slot-scope="scope">
              <span v-if="!scope.row.show">
                <el-button @click="editRow(scope.row)" type="text" size="small">编辑</el-button>
              </span>
              <span v-else><el-button @click="saveRow(scope.row)" type="text" size="small">保存</el-button></span>
              /<el-button @click="canRow(scope.row)" type="text" size="small">取消</el-button>
              <el-button v-on:click="deleteRow(scope.$index, tableData, scope.row)" type="text" size="small" style="width:100px; height: 50px;" round>删除</el-button>
            </template>
          </el-table-column>
        </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      tableData: [{
        techExeOff: 'c',
        techItem: 'c',
        techType: 'c',
        techNum: 'c',
        techOpe: 'c',
        techUnitPrice: 'c',
        show: false
      }
      ],
      form: {
        techItem: ''
      },
      childform: {
        techNum: '',
        techItem: '',
        techType: '',
        techUnitPrice: '',
        techExeOff: ''
      }
    }
  },
  created () {
    this.loading()
  },
  methods: {
    onSubmit () {
      console.log(this.form)
    },
    editRow (rows) {
      rows.show = true
    },
    canRow (rows) {
      rows.show = false
    },
    async saveRow (rows) {
      const {
        data: res
      } = await this.$http.post('updateTech', rows)
      if (res.flag === 'yes') {
        this.$message.success('修改成功！')
      } else {
        this.$message.error('修改失败！')
      }
      rows.show = false
    },
    async loading () {
      const {
        data: res
      } = await this.$http.get('getAllTech')
      console.log(res)
      this.tableData = res.techs
    },
    async Inquiry () {
      const {
        data: res
      } = await this.$http.post('getTechBy', this.form)
      console.log(res)
      if (res.flag === 'yes') {
        this.$message.success('查询成功！')
        this.tableData = res.techs
      } else {
        this.$message.error('没有该医技信息！')
        this.tableData = []
      }
    },
    async addDepart () {
      const {
        data: res
      } = await this.$http.post('addTech', this.childform)
      if (res.flag === 'yes') {
        this.$message.success('添加成功！')
      } else {
        this.$message.error('已存在该医技！')
      }
      this.dialogVisible=false;
    },
    deleteRow (index, rows, row) {
      this.delete('deleteByTechNum', row)
      rows.splice(index, 1)
    },
    async delete (str, row) {
      const {
        data: res
      } = await this.$http.post(str, row)
      console.log(res)
    }
  }
}
</script>

<style>
  .el-icon-my-export{
      background: url(../../assets/image/支付宝.png) center no-repeat;
      background-size: cover;
  }
  .el-icon-my-export:before{
      content: "替";
      font-size: 85px;
      visibility: hidden;
  }
  .el-icon-my-export1{
      background: url(../../assets/image/微信.png) center no-repeat;
      background-size: cover;
  }
  .el-icon-my-export1:before{
      content: "替";
      font-size: 85px;
      visibility: hidden;
  }
  .kkl{
    display: inline-block !important;
  }
  .kkk {
    display: inline-block !important;
  }

  .kkk1 {
    display: inline-block !important;
    ;
  }
</style>
