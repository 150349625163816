<template>
	<div>
		<div style="height:590px !important; background-color: #E9EEF3; font-size: 20px;"
			class="grid-content bg-purple">
			<el-row>
				<el-col :span="14">
					<div
						style="display:flex; background-color: #B3C0D1; margin: 10px; padding-top: 5px; padding-bottom: 10px;">
						<span style="padding-left: 10px;">
							<el-button icon="el-icon-plus" type="primary" @click="dialogVisible1 = true">
								新增
							</el-button>
							<el-dialog title="新增检验" :visible.sync="dialogVisible1" width="37%"
								:before-close="handleClose" :append-to-body="true">
								<div style="overflow:hidden">
									<el-form ref="form11" :model="form" label-width="80px" style="padding-top: 20px;">
										<div style="padding-left: 0px;">
											<el-form-item label="执行科室" class="kkl">
												<el-select v-model="form.comTechExeOff" placeholder="请选择科室"
													@change="departOnchange1()">
													<el-option v-for="item in departoptions1" :key="item.value"
														:label="item.label" :value="item.value">
													</el-option>
												</el-select>
											</el-form-item>
											<el-form-item label="项目名称" class="kkl" style="padding-left: 40px;">
												<el-select v-model="form.comTechItem" placeholder="请选择项目"
													@change="itemOnchange1()">
													<el-option v-for="item in itemoptions1" :key="item.value"
														:label="item.label" :value="item.value">
													</el-option>
												</el-select>
											</el-form-item>
										</div>
										<el-row style="padding-left: 10px; padding-top: 20px;">
											<el-col :span="12">
												<span>医技编码：{{this.form.comTechNum}}</span>
											</el-col>
											<el-col :span="12">
												<span
													style="padding-left: 20px;">单价：{{this.form.comTechUnitPrice}}</span>
											</el-col>
										</el-row>
									</el-form>
								</div>
								<span slot="footer" class="dialog-footer">
									<el-button type="warning" @click="dialogVisible1 = false">取 消
									</el-button>
									<el-button type="success" @click="addtech()">确 定</el-button>
								</span>
							</el-dialog>
						</span>
						<span style="padding-left: 100px;">
							<el-button icon="el-icon-delete" type="danger" @click="delectItem ()">删
								除</el-button>
						</span>
						<span style="padding-left: 260px;">
							<el-button icon="el-icon-check" type="success" @click="dialogFormAdd ()">开 立</el-button>
						</span>
					</div>
					<div style="margin: 10px;">
						<el-table ref="multipleTable" :data="tableData" max-height="490px" tooltip-effect="dark"
							border="true" :header-cell-style="{'text-align':'center'}" height="490px"
							@selection-change="handleSelectionChange" style="width: 100%;">
							<el-table-column type="selection" width="50" align="center">
							</el-table-column>
							<el-table-column prop="techNum" label="医技编码" align="center">
							</el-table-column>
							<el-table-column prop="techItem" label="项目" align="center">
							</el-table-column>
							<el-table-column prop="techExeOff" label="执行科室" align="center">
							</el-table-column>
							<el-table-column prop="techUnitPrice" label="单价" align="center">
							</el-table-column>
							<el-table-column prop="check_State" label="状态" align="center">
							</el-table-column>
<!--							<el-table-column label="检验结果" align="center">
								<el-button type="text" icon="el-icon-view" @click="onSubmit ()">查看</el-button>
							</el-table-column> -->
						</el-table>
					</div>
				</el-col>
				<el-col :span="10">
					<div style="margin-left: 5px; text-align: left; margin-top: 10px; line-height: 40px;">
						<div style="margin: 10px; padding: 5px; padding-bottom: 9px; background-color: #b3c0d1;">
							常用项目：
							<span style="padding-left: 253px;">
								<el-button icon="el-icon-plus" type="primary" @click="dialogVisible = true">新增
								</el-button>
							</span>
						</div>
						<el-dialog title="新增常用项目" :visible.sync="dialogVisible" width="37%" :before-close="handleClose"
							:append-to-body="true">
							<div>
								<el-form ref="form" :model="childform" label-width="80px" style="padding-top: 20px;">
									<div style="padding-left: 0px;">
										<el-form-item label="执行科室" class="kkl" style="padding-left: 0px;">
											<el-select v-model="childform.comTechExeOff" placeholder="请选择科室"
												@change="departOnchange()">
												<el-option v-for="item in departoptions" :key="item.value"
													:label="item.label" :value="item.value">
												</el-option>
											</el-select>
										</el-form-item>
										<el-form-item label="项目名称" class="kkl" style="padding-left: 40px;">
											<el-select v-model="childform.comTechItem" placeholder="请选择项目"
												@change="itemOnchange()">
												<el-option v-for="item in itemoptions" :key="item.value"
													:label="item.label" :value="item.value">
												</el-option>
											</el-select>
										</el-form-item>
									</div>
									<div>
										<el-row style="padding-left: 10px; padding-top: 20px;">
											<el-col :span="12">
												<span>医技编码：{{this.childform.comTechNum}}</span>
											</el-col>
											<el-col :span="12">
												<span
													style="padding-left: 20px;">单价：{{this.childform.comTechUnitPrice}}</span>
											</el-col>
										</el-row>
									</div>
								</el-form>
							</div>
							<span slot="footer" class="dialog-footer">
								<el-button type="warning" @click="dialogVisible = false">取 消</el-button>
								<el-button type="success" @click="addcomItem()">确 定</el-button>
							</span>
						</el-dialog>
					</div>
					<div class="npc" style="padding-left: 5px; margin: 10px; padding-top: 0px;">
						<el-table ref="multipleTable" :data="childtable" tooltip-effect="dark" border="true"
							height="490px" max-height="490" :header-cell-style="{'text-align':'center'}"
							style="width: 100%">
							<el-table-column prop="comTechNum" label="医技编码" align="center">
							</el-table-column>
							<el-table-column prop="comTechItem" label="项目" align="center">
							</el-table-column>
							<el-table-column prop="comTechExeOff" label="执行科室" align="center">
							</el-table-column>
							<el-table-column prop="comTechUnitPrice" label="单价" align="center">
							</el-table-column>
							<el-table-column label="操作" align="center" width="140px">
								<template slot-scope="scope">
									<el-button type="text" v-on:click="addItem(scope.row)">使用</el-button>
									<el-button type="text" v-on:click="deleteRow(scope.$index, childtable, scope.row)">
										<span style="color: #f56c6c;">
											删除
										</span>
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible1: false,
				dialogVisible: false,
				tableData: [{
						techNum: '',
						techItem: '',
						techExeOff: '',
						techUnitPrice: '',
						check_State: '',
						techTime: ''
					},
					{
						techNum: '',
						techItem: '',
						techExeOff: '',
						techUnitPrice: '',
						check_State: '',
						techTime: ''
					}
				],
				childtable: [{ //下面的
					comTechNum: '',
					comTechItem: '',
					comTechExeOff: '',
					comTechUnitPrice: ''
				}],
				childform: { //面的新增表单
					comTechNum: '',
					comTechItem: '',
					comTechExeOff: '',
					comTechUnitPrice: ''
				},
				form: { //面的新增表单
					comTechNum: '',
					comTechItem: '',
					comTechExeOff: '',
					comTechUnitPrice: ''
				},
				departoptions: [{
						label: '内科',
						value: '内科'
					},
					{
						label: '外科',
						value: '外科'
					}
				],
				itemoptions: [{
						label: '验血',
						value: '验血'
					},
					{
						label: '验尿',
						value: '验尿'
					}
				],
				departoptions1: [{
						label: '内科',
						value: '内科'
					},
					{
						label: '外科',
						value: '外科'
					}
				],
				itemoptions1: [{
						label: '验血',
						value: '验血'
					},
					{
						label: '验尿',
						value: '验尿'
					}
				],
				multipleTable: [],
				multipleTable1: [{
					techNum: ''
				}],
			}
		},
		created() {
			this.loading2()
			this.loading()
		},
		computed: {
			sstate() {
				return this.$store.state.sstate
			}
		},
		watch: {
			sstate(val) {
				this.loading()
			}
		},
		methods: {
			onSubmit() {
				console.log(this.form)
			},
			async loading2() {
				const {
					data: res
				} = await this.$http.get('getallxz')
				this.departoptions = res.allxz;
				this.departoptions1 = res.allxz;
			},
			async loading() {
				const {
					data: res
				} = await this.$http.post("getallinspe", {
					"patient_reg_id": this.$store.state.sstate
				});
				if (res.isAdd == "true") {
					this.$message.success("获取成功！");
					this.dialogVisible = false;
					this.tableData = res.inspec;
					this.childtable = res.comins
				}
			},
			async departOnchange() {
				const {
					data: res
				} = await this.$http.post("getallxm", {
					"depart_id": this.childform.comTechExeOff
				});
				this.itemoptions = res.item;
			},
			async departOnchange1() {
				const {
					data: res
				} = await this.$http.post("getallxm", {
					"depart_id": this.form.comTechExeOff
				});
				this.itemoptions1 = res.item;

			},
			async itemOnchange() {
				const {
					data: res
				} = await this.$http.post("getform", {
					"tech_id": this.childform.comTechItem
				});
				this.childform = res.xinzen
			},
			async itemOnchange1() {
				const {
					data: res
				} = await this.$http.post("getform", {
					"tech_id": this.form.comTechItem
				});
				this.form = res.xinzen
			},
			deleteRow(index, rows, row) {
				this.delete(row)
				rows.splice(index, 1)
			},
			async delete(row) { //常用项删除
				const {
					data: res
				} = await this.$http.post("deleteById", row)
				if (res.flag === "yes") {
					this.$message.success("删除成功！")
				} else {
					this.$message.error("删除失败！")
				}
			},
			handleSelectionChange(val) {
				this.multipleTable = val
			},
			async dialogFormAdd() { //开立
				const {
					data: res
				} = await this.$http.post('update', {
					"mult": this.multipleTable,
					"check_RegID": this.$store.state.sstate
				})
				if (res.flag === "yes") {
					this.$message.success("开立成功！")
				} else {
					this.$message.error("开立失败！")
				}
				this.tableData = res.table
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						done()
					})
					.catch(_ => {})
			},
			async addItem(row) { //常用项添加到上面
				const {
					data: res
				} = await this.$http.post('addIns', {
					"comTechNum": row.comTechNum,
					"check_RegID": this.$store.state.sstate
				})
				if (res.flag === 'yes') {
					this.$message.success('添加成功！')
					this.tableData = res.table
				} else {
					this.$message.error('该检验已存在！')
				}
				this.dialogVisible = false
			},
			async addtech() { //上面的提交
				const {
					data: res
				} = await this.$http.post("addtech", {
					"comTechNum": this.form.comTechNum,
					"check_RegID": this.$store.state.sstate
				})
				if (res.flag === 'yes') {
					this.$message.success('添加成功！')
					this.tableData = res.table
				} else {
					this.$message.error('该常用项目已存在！')
				}
				this.dialogVisible1 = false
			},
			async delectItem() { //上面删除
				const {
					data: res
				} = await this.$http.post('delectItem', {
					"mult": this.multipleTable,
					"check_RegID": this.$store.state.sstate
				})
				if (res.flag === "yes") {
					this.$message.success("删除成功！")
				} else {
					this.$message.error("删除失败！")
				}
				this.tableData = res.inspec;
			},
			async addcomItem() { //下面的提交
				const {
					data: res
				} = await this.$http.post('addcomItem', this.childform)
				if (res.flag === 'yes') {
					this.$message.success('添加成功！')
					this.childtable = res.childtable
				} else {
					this.$message.error('该检验已存在！')
				}
				this.dialogVisible = false
			}
		}
	}
</script>

<style>
	.uuu .el-form-item__label {
		font-size: 22px !important;
		font-weight: 100px;
		line-height: 40px;
	}
</style>
