<template>
	<div class="login_backgound">
		<el-form ref="loginForm" :model="form" :rules="rules" label-width="80px" class="login-box" >
			<h3 class="login-title">欢迎登录</h3>
			<el-form-item label="账号" prop="id">
				<el-input prefix-icon="iconfont icon-yonghu-fuben" type="text" placeholder="请输入账号" v-model="form.id" />
			</el-form-item>
			<el-form-item label="密码" prop="password">
				<el-input prefix-icon="iconfont icon-mima" type="password" placeholder="请输入密码" v-model="form.password"
					show-password />
			</el-form-item>
			<el-form-item>
				<el-button type="primary" v-on:click="onSubmit('loginForm')">登录</el-button>
				<el-button type="primary" v-on:click="onSubmit('loginForm')">注册</el-button>
			</el-form-item>
		</el-form>

		<el-dialog title="温馨提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<span>请输入账号和密码</span>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: "Login",
		data() {
			return {
				form: {
					id: 'admin',
					password: '123456'
				},

				// 表单验证，需要在 el-form-item 元素中增加 prop 属性
				rules: {
					id: [{
						required: true,
						message: '账号不可为空',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '密码不可为空',
						trigger: 'blur'
					}]
				},

				// 对话框显示和隐藏
				dialogVisible: false
			}
		},
		methods: {
			onSubmit(formName) {
				// 为表单绑定验证功能
				this.$refs[formName].validate(async (valid) => {
					if (!valid) {
						this.$message.error("验证失败！");
						return;
					}
					// 使用 vue-router 路由到指定页面，该方式称之为编程式导航
					const {
						data: res
					} = await this.$http.post("login", this.form);
					if (res.flag == "yes") {
						this.$message.success("登录成功！");
						window.sessionStorage.setItem("user", JSON.stringify(res.user));
						window.sessionStorage.setItem('accessToken', true)
						//sessionStorage.setItem("token", 'true');
						this.$router.push("/myvue");
					} else {
						this.$message.error("账户密码错误！");
					}
				});
			},
			login() {

			}
		}
	}
</script>

<style>
	.login_backgound {
		background: url("../../assets/image/his1.png") no-repeat;
		background-position: center;
		height: 100%;
		width: 100%;
		background-size: cover;
		position: fixed;

	}


	.login-box {
		border: 1px solid #DCDFE6;
		width: 350px;
		margin: 180px auto;
		padding: 35px 35px 15px 35px;
		border-radius: 5px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		box-shadow: 0 0 25px #909399;
	}

	.login-title {
		text-align: center;
		margin: 0 auto 40px auto;
		color: #303133;
	}

	.login-box {
		z-index: 300;
		background-repeat: no-repeat;
		background-position: center center;
		background-color: #000;
		background-color: rgba(234, 229, 229, 0.5);
	}
</style>
