<template>
  <div>
    <div style="overflow:hidden">
      <el-form ref="form" :model="form" label-width="80px" style="padding-top: 20px;">
        <div style="float: left; padding-left: 200px;">
          <el-form-item label="*科室名" class="kkk">
            <el-input v-model="form.depName" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="*类别" class="kkk">
            <el-input v-model="form.depType" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="*编号" class="kkk">
            <el-input v-model="form.depNum" placeholder="请输入内容"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <el-button icon="el-icon-search" type="primary" v-on:click="onSubmit()" style="width:120px; height: 50px;" round>搜索</el-button>
      <el-button icon="el-icon-plus" type="success" @click="dialogVisible = true" style="width:120px; height: 50px;" round>新增科室</el-button>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="40%" :append-to-body="true"
        :before-close="handleClose">

        <div style=" margin-left:100px ;">
          <el-form ref="form1" :model="childform" label-width="80px" style="padding-top: 20px;">
            <div style="">
              <el-form-item label="科室名称" class="kkk">
                <el-input v-model="childform.depName" placeholder="请输入内容"></el-input>
              </el-form-item>
              <el-form-item label="科室编号" class="kkk">
                <el-input v-model="childform.depNum" placeholder="请输入内容"></el-input>
              </el-form-item>
            </div>
            <div style="">
              <el-form-item label="科室类型" class="kkk">
                <el-input v-model="childform.depType" placeholder="请输入内容"></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" v-on:click="addDepart()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <div style="padding-left: 0px; margin-left: 250px; margin-top:20px ;">
      <el-table ref="multipleTable" :data="tableData" height="300" tooltip-effect="dark" border="true"
        :header-cell-style="{'text-align':'center'}"
        style="width: 80%; padding-left: 20px;">
        <el-table-column prop="depNum" label="编号" align="center" width="240">
        </el-table-column>
        <el-table-column prop="depName" label="科室名" align="center" width="240">
          <template slot-scope="scope">
            <span v-if="scope.row.show">
              <el-input size="mini" v-model="scope.row.depName"></el-input>
            </span>
            <span v-else>{{ scope.row.depName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="depType" label="类别" align="center" width="240">
          <template slot-scope="scope">
            <span v-if="scope.row.show">
              <el-input size="mini" v-model="scope.row.depType"></el-input>
            </span>
            <span v-else>{{ scope.row.depType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" align="center" width="340">
          <template slot-scope="scope">
            <span v-if="!scope.row.show">
              <el-button @click="editRow(scope.row)" type="text" size="small">编辑</el-button>
            </span>
            <span v-else><el-button @click="saveRow(scope.row)" type="text" size="small">保存</el-button></span>
            /<el-button @click="canRow(scope.row)" type="text" size="small">取消</el-button>
            <el-button v-on:click="deleteRow(scope.$index, tableData, scope.row)" type="text" size="small" style="width:100px; height: 50px;" round>删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      tableData: [{
        depNum: '',
        depName: '',
        depType: '',
        show: false
      }],
      form: {
        depNum: '',
        depName: '',
        depType: ''
      },
      childform: {
        depNum: '',
        depName: '',
        depType: ''
      }
    }
  },
  created () {
    this.loading()
  },
  methods: {
    onSubmit () {
      this.Inquiry()
    },
    editRow (rows) {//编辑
      rows.show = true
    },
    canRow (rows) {
      rows.show = false
    },
    async saveRow (rows) {
      const {
        data: res
      } = await this.$http.post('updateDep', rows)
      if (res.flag === 'yes') {
        this.$message.success('修改成功！')
      } else {
        this.$message.error('修改失败！')
      }
      rows.show = false
    },
    async loading () {
      const {
        data: res
      } = await this.$http.get('getAllDep')
      this.tableData = res.deps
    },
    async Inquiry () {
      const {
        data: res
      } = await this.$http.post('getDepBy', this.form)
      console.log(res)
      if (res.flag === 'yes') {
        this.$message.success('查询成功！')
        this.tableData = res.deps
      } else {
        this.$message.error('没有科室信息！')
        this.tableData = []
      }
    },
    async addDepart () {
      console.log(this.childform);
      const {
        data: res
      } = await this.$http.post('addDep', this.childform)
      if (res.flag === 'yes') {
        this.$message.success('添加成功！')
      } else {
        this.$message.error('已存在该科室！')
      }
      this.dialogVisible = false
      this.loading()
    },
    deleteRow (index, rows, row) {
      this.delete('deleteByNum', row);
      rows.splice(index, 1)
    },
    async delete (str, row) {
      const {
        data: res
      } = await this.$http.post(str, row)
      console.log(res)
    }
  }
}
</script>

<style>
  .uuu .el-form-item__label {
    font-size: 22px !important;
    font-weight: 100px;
    line-height: 40px;
  }
</style>
