<template>
	<el-row :gutter="20">
		<el-col :span="18">
			<div style="background-color: #B3C0D1; font-size: 30px;" class="grid-content bg-purple">
				<span
					style="padding-left: 200px; line-height: 53px;">当前患者：{{this.table.patient_Name}}&nbsp;年龄：{{this.table.patient_Age}}&nbsp;病历号：{{this.table.patient_ID}}</span>
			</div>
			<div>
				<div style="text-align: left !important; margin: 10px; font-size: 24px;">
					处置信息：
				</div>
				<div style="">
					<el-table ref="multipleTable" :data="magtable" height="500" max-height="500" tooltip-effect="dark"
						border="true" @selection-change="handleSelectionChange" style="width: 100%;">
						<el-table-column type="selection" width="45">
						</el-table-column>
						<el-table-column prop="name" label="检验名称">
						</el-table-column>
						<el-table-column prop="num" label="数量">
						</el-table-column>
						<el-table-column prop="unp" label="单价">
						</el-table-column>
						<el-table-column prop="totalp" label="总价">
						</el-table-column>
						<el-table-column prop="state" label="状态">
						</el-table-column>
						<el-table-column label="上传">
							<el-button>上传</el-button>
						</el-table-column>
					</el-table>
				</div>
				<div style="float:right !important; padding-right: 280px; padding-top: 35px;">
					<el-button icon="el-icon-check" type="success" v-on:click="onSubmit()" style="width:140px; height: 50px;" round>确定检验</el-button>
				</div>
			</div>
		</el-col>
		<el-col :span="6">
			<div style="height: 90px; overflow:hidden; background-color: #f5f7fa; border-radius: 2px;"
				class="grid-content bg-purple">
				<el-form ref="form" :model="form" label-width="80px" style="padding-top: 20px;">
					<div style="float: left; background-color: #f5f7fa;">
						<el-form-item label="姓名">
							<el-input style="width:150px;" v-model="form.patient_Name" placeholder="请输入内容"></el-input>
							&nbsp;&nbsp;&nbsp;
							<el-button icon="el-icon-search" type="primary" v-on:click="Inquiryuser()" style="width:100px; height: 40px;" round>查询
							</el-button>
						</el-form-item>
					</div>
				</el-form>
			</div>
			<div style="margin-bottom: 60px;">
				<el-table :data="tableData" max-height="270px" style="width: 100%;"
					:header-cell-style="{'text-align':'center'}">
					<el-table-column label="未处置">
						<el-table-column prop="reg_Sn" label="序号" width="130" align="center">
						</el-table-column>
						<el-table-column prop="patient_Name" label="姓名" width="126" align="center">
						</el-table-column>
						<el-table-column label="操作" width="129" align="center">
							<template slot-scope="scope">
								<el-button @click="selectRow(scope.row)" type="text" size="small">
									选择
								</el-button>
							</template>
						</el-table-column>
					</el-table-column>
				</el-table>
			</div>
			<div>
				<el-table :data="tableData1" max-height="270px" style="width: 100%;"
					:header-cell-style="{'text-align':'center'}">
					<el-table-column label="已处置">
						<el-table-column prop="reg_Sn" label="序号" width="130" align="center">
						</el-table-column>
						<el-table-column prop="patient_Name" label="姓名" width="126" align="center">
						</el-table-column>
						<el-table-column label="操作" width="129" align="center">
							<template slot-scope="scope">
								<el-button @click="selectRow(scope.row)" type="text" size="small">
									选择
								</el-button>
							</template>
						</el-table-column>
					</el-table-column>
				</el-table>
			</div>
		</el-col>
	</el-row>
</template>

<script>
	export default {
		data() {
			return {
				magtable: [{
					name: 'c',
					num: 'c',
					unp: 'c',
					totalp: 'c',
					state: 'c'
				}],
				docid: '',
				tableData: [{
					reg_Sn: 'a',
					patient_Name: 'a',
					reg_ID: 'a'
				}],
				tableData1: [{
					reg_Sn: 'a',
					patient_Name: 'a',
					reg_ID: 'a'
				}],
				form: {
					patient_Name: 'a'
				},
				table: [{
					patient_Name: 'a',
					patient_Age: 'a',
					patient_ID: 'a'
				}]
			}
		},
		created() {
			this.loading()
		},
		methods: {
			async loading() {
				this.docid = JSON.parse(window.sessionStorage.getItem("user")).id;
				const {
					data: res
				} = await this.$http.post('loading', {
					"docid": this.docid
				})

				this.tableData = res.patiswz
				this.tableData1 = res.patisyz
			},
			onSubmit() { //诊毕
			},
			async Inquiryuser() { //查询患者，输入姓名
				const {
					data: res
				} = await this.$http.post('getUserTable', {
					"docid": this.docid,
					"patname": this.form.patient_Name
				})
				this.tableData = res.patisbydpwz
				this.tableData1 = res.patisbydpyz
			},
			async selectRow(rows) {
				const {
					data: res
				} = await this.$http.post('getUserdata', {
					"reg_ID": rows.reg_ID
				})
				this.table = res.paups
				this.$store.state.sstate = rows.reg_ID
				console.log("a:" + this.$store.state.sstate)
			}
		}
	}
</script>

<style>
	.uuu .el-form-item__label {
		font-size: 22px !important;
		font-weight: 100px;
		line-height: 40px;
		width: 100% !important;
		text-align: left;
	}
</style>
