<template>
  <div>
    <div style="overflow:hidden">
      <el-form ref="form" :model="form" label-width="80px" style="padding-top: 20px;">
        <div style="float: left; padding-left: 198px;">
          查询药物名称：
          <el-form-item label="" class="kkk1">
            <el-input v-model="form.med_Name" placeholder="请输入内容"></el-input>
          </el-form-item>
        </div>
        <el-button icon="el-icon-search" type="primary" v-on:click="onSubmit('form')" round>查询</el-button>
        <el-button icon="el-icon-circle-plus-outline" type="success" @click="dialogVisible = true"  round>新增药物</el-button>
      </el-form>
    </div>
    <div style="padding-left: 20px;">
      <el-table
          ref="multipleTable"
          :data="tableData"
          height="500"
          tooltip-effect="dark"
          border="true"
          @selection-change="handleSelectionChange"
          :header-cell-style="{'text-align':'center'}"
          style="width: 100%;">
          <el-table-column
            type="selection"
            align="center"
            width="45">
          </el-table-column>
          <el-table-column
            prop="med_ID"
            label="药品编码"
            align="center"
            width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.show">
                <el-input size="mini" v-model="scope.row.med_ID"></el-input></span>
                <span v-else>{{ scope.row.med_ID }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="med_Name"
            label="药品名称"
            align="center"
            width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.show">
                <el-input size="mini" v-model="scope.row.med_Name"></el-input></span>
                <span v-else>{{ scope.row.med_Name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="med_Spec"
            label="药品规格"
            align="center"
            width="130">
            <template slot-scope="scope">
              <span v-if="scope.row.show">
                <el-input size="mini" v-model="scope.row.med_Spec"></el-input></span>
                <span v-else>{{ scope.row.med_Spec }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="med_Un"
            label="包装单位"
            align="center"
            width="130">
            <template slot-scope="scope">
              <span v-if="scope.row.show">
                <el-input size="mini" v-model="scope.row.med_Un"></el-input></span>
                <span v-else>{{ scope.row.med_Un }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="med_Manu"
            label="生产厂家"
            align="center"
            width="140">
            <template slot-scope="scope">
              <span v-if="scope.row.show">
                <el-input size="mini" v-model="scope.row.med_Manu"></el-input></span>
                <span v-else>{{ scope.row.med_Manu }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="med_Type"
            label="药品类型"
            align="center"
            width="140">
            <template slot-scope="scope">
              <span v-if="scope.row.show">
                <el-input size="mini" v-model="scope.row.med_Type"></el-input></span>
                <span v-else>{{ scope.row.med_Type }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="med_UnitPrice"
            label="药品单价"
            align="center"
            width="130">
            <template slot-scope="scope">
              <span v-if="scope.row.show">
                <el-input size="mini" v-model="scope.row.med_UnitPrice"></el-input></span>
                <span v-else>{{ scope.row.med_UnitPrice }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="med_Kind"
            label="中/西药"
            align="center"
            width="140">
            <template slot-scope="scope">
              <span v-if="scope.row.show">
                <el-input size="mini" v-model="scope.row.med_Kind"></el-input></span>
                <span v-else>{{ scope.row.med_Kind }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="med_State"
            label="药品状态"
            align="center"
            width="140">
            <template slot-scope="scope">
              <span v-if="scope.row.show">
                <el-input size="mini" v-model="scope.row.med_State"></el-input></span>
                <span v-else>{{ scope.row.med_State }}</span>
            </template>
          </el-table-column>
          <el-table-column
          label="操作"
          align="center"
          width="250">
            <template slot-scope="scope">
              <el-button icon="el-icon-delete" type="danger" v-on:click="deleteRow(scope.$index, tableData, scope.row)" circle></el-button>
              <el-button icon="el-icon-edit" type="primary" @click="editRow(scope.row)" circle></el-button>
			  <el-button icon="el-icon-circle-check" type="info" @click="saveRow(scope.row)" circle></el-button>
            </template>
          </el-table-column>
        </el-table>
    </div>
    <div style="margin-top:180px; padding-left: 850px;">
      <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="40%"
        :append-to-body="true"
        :before-close="handleClose">

        <div style=" margin-left:100px ;">
          <el-form ref="form1" :model="childTable" label-width="80px" style="padding-top: 20px;">
            <div style="">
              <el-form-item label="药品编码:" class="kkk">
                <el-input v-model="childTable.med_ID" placeholder="请输入内容"></el-input>
              </el-form-item>
              <el-form-item label="药品名称:" class="kkk">
                <el-input v-model="childTable.med_Name" placeholder="请输入内容"></el-input>
              </el-form-item>
            </div>
            <div style="">
              <el-form-item label="药品规格:" class="kkk">
                <el-input v-model="childTable.med_Spec" placeholder="请输入内容"></el-input>
              </el-form-item>
            </div>
            <div style="">
              <el-form-item label="包装单位:" class="kkk1">
                <el-input v-model="childTable.med_Un" placeholder="请输入内容"></el-input>
              </el-form-item>
              <el-form-item label="生产厂家:" class="kkk1">
                <el-input v-model="childTable.med_Manu" placeholder="请输入内容"></el-input>
              </el-form-item>
            </div>
            <div style="">
              <el-form-item label="药品类型:" class="kkk1">
                <el-input v-model="childTable.med_Type" placeholder="请输入内容"></el-input>
              </el-form-item>
              <el-form-item label="单价:" class="kkk1">
                <el-input v-model="childTable.med_UnitPrice" placeholder="请输入内容"></el-input>
              </el-form-item>
              <el-form-item label="中/西药:" class="kkk1">
                <el-input v-model="childTable.med_Kind" placeholder="请输入内容"></el-input>
              </el-form-item>
              <el-form-item label="药品状态:" class="kkk1">
                <el-input v-model="childTable.med_State" placeholder="请输入内容"></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" v-on:click="addMeds ()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      multipleSelection: [] ,//返回的是选中的列的数组集合　  这里接收用户选中的id  默认放在这个数组里面　
      childTable: {
        med_ID: '',
        med_Name: '',
        med_Spec: '',
        med_Un: '',
        med_Manu: '',
        med_Type: '',
        med_UnitPrice: '',
        med_Kind: '',
        med_State: ''
      },
      tableData: [{
        med_ID: '',
        med_Name: '',
        med_Spec: '',
        med_Un: '',
        med_Manu: '',
        med_Type: '',
        med_UnitPrice: '',
        med_Kind: '',
        med_State: '',
        show: false
      }],
      dialogVisible: false,
      value: ' ',
      form: {
        med_Name: ''
      }
    }
  },
  created() { //页面一加载就读取
    this.getALLMeds();
  },
  methods: {
    onSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
			if(!valid) {
				this.$message.error("验证失败！");
				return;
			}
			// 使用 vue-router 路由到指定页面，该方式称之为编程式导航
			const{data:res} = await this.$http.post("getMedicineBy",this.form);
			if(res.flag == "yes"){
				this.$message.success("查询成功！");
				this.tableData = res.meds;
			}else{
				this.$message.error("没有该药品！");
				this.tableData = [];
			}
		});
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
	editRow (rows){
	    rows.show = true;
	},
	deleteRow (index, rows, row) {
	  this.delete("deleteMedicineByID",row,"删除");
		rows.splice(index, 1);
	},
	async saveRow (rows){
		const {
		  data: res
		} = await this.$http.post("updateMedicine", rows)
		if(res.flag==="yes"){
		  this.$message.success("保存成功！");
		}else{
		  this.$message.error("保存失败！");
		}
	    rows.show = false;
	},
    async getALLMeds() {
      const {
      	data: res
      } = await this.$http.get("getAllMedicine");
      this.tableData = res.meds;
    },
    async delete (str, row,strs) {//保存
      const {
        data: res
      } = await this.$http.post(str, row)
      if(res.flag==="yes"){
        this.$message.success(strs+"成功！");
      }else{
        this.$message.error(strs+"失败！");
      }
    },
    async addMeds () {
    		// 使用 vue-router 路由到指定页面，该方式称之为编程式导航
          const {
          	data: res
          } = await this.$http.post("addMedicine",this.childTable);
    		if(res.flag == "yes"){
    			this.$message.success("添加成功！");
    		}else{
    			this.$message.error("已存在该药品！");
    		}
        this.dialogVisible = false;
        this.getALLMeds();
    },
  }
}
</script>

<style>

</style>
